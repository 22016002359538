import {
  GET_EVENT,
  EVENT_ERROR,
  CLEAR_EVENT,
  UPDATE_EVENT,
  GET_EVENTS
} from '../actions/types';

const initialState = {
  event: null,
  eventlog: [],
  loadinglog: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENT:
    case UPDATE_EVENT:
      return {
        ...state,
        event: payload,
        loadinglog: false
      };
    case GET_EVENTS:
      return {
        ...state,
        eventlog: payload,
        loadinglog: false
      };
    case EVENT_ERROR:
      return {
        ...state,
        error: payload,
        loadinglog: false,
        event: null
      };
    case CLEAR_EVENT:
      return {
        ...state,
        event: null,
        eventlog: [],
        loadinglog: true
      };

    default:
      return state;
  }
}
