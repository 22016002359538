import {
  GET_CODE,
  GET_CODES,
  CODE_ERROR,
  UPDATE_CODE,
  CLEAR_CODE,
  CODE_DELETED
} from '../actions/types';

const initialState = {
  code: null,
  codes: [],
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CODE:
    case UPDATE_CODE:
      return {
        ...state,
        code: payload,
        loading: false
      };
    case GET_CODES:
      return {
        ...state,
        codes: payload,
        loading: false
      };
    case CODE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        code: null,
        codes: []
      };
    case CLEAR_CODE:
      return {
        ...state,
        code: null,
        codes: [],
        loading: false
      };
    default:
      return state;
  }
}
