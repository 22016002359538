import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      fr: {
        translation: {
          titre_app: "Générateur de code d'accès",
          msg_version_min:
            'Ce code est utilisable sur un MC603 dont la version est supérieure ou égale à la version 03.01.00',
          codes: 'Codes',
          users: 'Utilisateurs',
          entities: 'Entités',
          my_account: 'Mon compte',
          log: 'Journal',
          logout: 'Deconnexion',
          unauthorized_access: 'Accès non autorisé',
          cancel: 'Annuler',
          // Landing
          landing_title: 'Générateur code accès ASCOREL',
          landing_lead:
            ' Bienvenue, veuillez vous connecter pour accéder aux fonctionnalités',
          //Dashboard
          dashboard: 'Tableau de bord',
          code_lead: 'Génération et gestion de codes',
          code_lead_user: 'Afficher vos codes',
          users_lead: 'Gestions comptes',
          entities_lead: 'Gestion entités',
          //Codes
          assigned_codes_title: 'Codes assignés',
          assigned_codes_lead: "Codes dont vous êtes l'utilisateur",
          created_codes_title: 'Historique codes crées',
          created_codes_lead: 'Codes dont vous êtes le créateur',
          code_history: 'Historique codes',
          code_create: 'Création code',
          code_quick: 'Quick code',
          valid_codes: 'Codes en cours de validité',
          invalid_codes: 'Codes non-valides',
          old_versions_codes: 'Codes pour versions antérieures à 03.02.xx',
          title_old_version_1: 'Pour les versions antérieures à 03.00.xx',
          title_old_version_2:
            'Pour les versions comprises entre 03.00.xx et 03.02.xx',
          code_cree_par: 'Crée par',
          code_cree_pour: 'Crée pour',
          code_produit: 'Produit',
          code_date_debut: 'Date début',
          code_date_fin: 'Date fin',
          code_date_create: 'Date création',
          code_duration: 'Durée',
          code_level: 'Niveau',
          level_1: '1 - Visualisation',
          level_2: '2',
          level_3: '3',
          level_4: '4 - Technicien',
          level_5: '5',
          level_6: '6',
          level_7: '7',
          level_8: '8 - Chef de projet',
          level_9: '9',
          level_10: '10',
          level_11: '11',
          level_12: '12',
          level_13: '13',
          level_14: '14 - Technicien Ascorel',
          level_15: '15 - Service après-vente Ascorel',
          level_16: "16 - Bureau d'études Ascorel",
          code_state: 'Etat',
          code_desc: 'Description',
          code_not_found_valid:
            "Aucun code d'accès valide en cours, contacter votre administrateur pour qu'un code vous soit attribué.",
          code_not_found: 'Aucun code trouvé',
          return_assigned_code: 'Retour codes assignés',
          waiting: 'En attente',
          expired: 'Perimé',
          valid: 'En cours',
          unknown: '???',
          //Code detail
          back_to_code_list: 'Retour liste codes',
          resend_email: 'Envoi email',
          resend_sms: 'Envoi SMS',
          deleted_code: 'Supprimer code',
          assigned_to: ' Assigné à',
          //Code create
          utilisateur_code: 'Utilisateur code',
          creer_code_title: 'Créer un nouveau code',
          quick_code_title: 'Quick code',
          creer_code_lead: ' Veuillez renseigner les champs ci-dessous :',
          quick_code_lead: ' Generation de code journalier pour soi même :',
          champs_requis: '* = champs requis',
          target_user_expl:
            "* Choississez l'utilisateur auquel vous souhaitez assigner le code",
          product_select: 'Selectionner produit',
          product_expl: '* Type de produit',
          start_date_expl: '* Date de début',
          duration_expl: '* Durée de validité du code',
          descri_expl: 'Description code',
          sms_send: 'Envoi SMS',
          email_send: 'Envoi Email',
          select_duration: 'Selectionner durée',
          day: 'Jour',
          week: 'Semaine',
          month: 'Mois',
          trimester: 'Trimestre',
          semester: 'Semestre',
          year: 'Année',
          create: 'Créer',
          niveau_small: '* Niveau autorisation code',
          //User list
          accounts: 'Compte',
          accounts_manag: 'Gestion comptes',
          user_create: 'Creation utilisateur',
          id: 'Identifiant',
          email: 'Email',
          phone: 'Téléphone',
          descri: 'Descriptif',
          rights: 'Droits',
          entity: 'Entité',
          last_seen: 'Dernière connexion',
          modify: 'Modifié',
          no_users_found: 'aucun utilisateur trouvé',
          activated: 'activé',
          deactivated: 'désactivé',
          all_header: 'Tous',
          //User detail
          back_dasboard: 'Retour tableau de bord',
          back_acc_list: 'Retour Liste comptes',
          modify_account: 'Modifier Utilisateur',
          deactivate_account: 'Desactiver compte',
          reactivate_account: ' Reactiver compte ',
          delete_account: ' Supprimer compte ',
          account_deactivated: 'Compte DESACTIVE',
          activate_msg: 'Etes vous sur de vouloir activer ce compte ?',
          deactivate_msg: 'Etes vous sur de vouloir desactiver ce compte ?',
          //User edit
          password_match_err: 'Les mots de passe ne correspondent pas',
          password_6_error:
            'Le mot de passe doit contenir 6 caractères minimum',
          create_account_title: 'Créer un nouveau compte',
          mod_account_title: 'Modifier le compte',
          create_account_lead: ' Veuillez renseigner les champs ci-dessous :',
          mod_account_lead: ' Modification des paramètres du compte :',
          must_unique: 'Doit etre unique',
          ph_id: '*Identifiant',
          ph_email: '*Email',
          phone_hint: 'Ajouter 0033 et enlever le premier 0, ex:0033651025511',
          phone_ph: '*Numero de téléphone',
          rights_hint: "Niveau d'accès",
          rights_select: '* Selectionner droits',
          rights_admin: 'Administrateur - experts Ascorel seulement',
          rights_admin_ent: 'Administrateur entité',
          rights_user: 'Utilisateur',
          entity_hint: 'Organisation',
          entity_select: '* Selectionner entité',
          name_hint: 'Choissisez un nom descriptif',
          name_ph: 'Descriptif',
          pass_hint: '6 caractères minimum',
          pass_ph: 'Modifier mot de passe',
          pass2_hint: 'Repetez le nouveau mot de passe pour confirmation',
          pass2_ph: 'Confirmer nouveau mot de passe',
          save: 'Enregistrer',
          create: 'Créer',
          activation_email_sent:
            "Un email d'activation va être envoyer à l'utilisateur, sa durée de validité est de 7 jours",
          //login
          login_lead: ' Renseigner vos informations de connexion',
          id_ph: 'Identifiant',
          pw_ph: 'Mot de passe',
          pw_fg: 'Mot de passe oublié ?',
          pw_fg1: 'Cliquer ici',
          //Not found
          page_not_found: '  Page inconnue',
          error_404: "Il semblerait qu'une erreur se soit produite",
          try_back: 'Veuillez réessayer plus tard',
          //Activate account
          act_acc_title:
            'Cliquer sur le bouton ci-dessous pour activer votre compte utilisateur',
          act_acc_button: 'Activer compte',
          act_acc_close: 'Compte activé, vous pouvez fermer cette page',
          acceptCGU: " J'accepte les <1>conditions générales d'utilisation</1>",
          acceptCGUWarning:
            " Veuillez accepter les conditions générales d'utilisation pour valider votre compte",
          passwordWarning:
            'Les mots de passe doivent correspondre et contenir 6 caractères minimum',
          //Reset password
          reset_pw_title1:
            "Pour activer votre compte, veuillez entrer votre nouveau mot de passe et accepter les conditions générales d'utilisation",
          reset_pw_title2: 'Veuillez entrez votre nouveau mot de passe',
          reset_pw_pw1: 'Nouveau mot de passe, 6 caractères minimum',
          reset_pw_pw2: 'Répéter nouveau de passe',
          success_account: 'Compte activé, vous pouvez fermer cette page',
          success_pw:
            'Mot de passe réinitialisé, vous pouvez fermer cette page.',
          //Verify email
          ver_email_title1: 'Mot de passe oublié ?',
          ver_email_title2:
            'Entrez votre adresse email ci-dessous pour recevoir un lien de reinitialisation du mot de passe.',
          ver_email_hint: "Adresse email communiquée lors de l'inscription.",
          ver_email_done:
            "L'email sera envoyé dans quelques minutes. Verifier votre dossier Spam si ce n'est pas le cas.",
          send: 'Envoyer',
          //Entity list
          entities_mngt: ' Gestion entités',
          entity_create: 'Création entité',
          name: 'Nom',
          lvl_max_admin: 'Niveau maximum administrateur entité',
          lvl_max_user: 'Niveau maximum utilisateur entité',
          no_entity_found: 'Aucune entité trouvée',
          //Entity list
          back_list_ent: ' Retour Liste entités',
          modify_entity: ' Modifier entité ',
          del_entity: 'Supprimer entité ',
          //Entity Create
          creat_entity_lvl: 'Créer une nouvelle entité',
          modify_entity_lvl: "  Modification des paramètres de l'entité :",
          select_admin_lvl: '* Selectionner niveau accès administrateur entité',
          admin_lvl_hint:
            "Niveau d'accès maximum des administrateurs de l'entité",
          select_user_lvl: ' * Selectionner niveau accès utilisateur',
          user_lvl_hint: "Niveau d'accès maximum des utilisateurs de l'entité",
          //FAQ :
          faq_lead:
            "Questions frequemment posées sur l'utilisation du Ascorel Code Generator",
          faq_quick_code_title: 'Fonctionnement Quick code',
          quick_code_msg:
            "Fonctionnalité de niveau 'Utilisateur'.\n\n Le bouton 'Quick code' present sur la pages principale des codes permet de générer des codes journaliers à usage personnel. Seul un jour de la semaine en cours peut etre selectionné.",
          consult_code_title: 'Comment consulter mes codes ?',
          consult_code_msg:
            "Pour consulter les codes qui vous ont été assigné, cliquer sur 'Codes' dans la barre de navigation en haut de la page. Dans cette page, les codes sont séparés en 2 listes, regroupant les codes en cours de validité et ceux expirés ou en attente de validité.\n\n Cliquer sur le bouton 'détails' de chaque ligne pour obtenir le code et l'identifiant nécessaires à l'entrée en calibration sur le système cible.\n\n Vous pouvez vous-même vous l’envoyer sur votre email ou par SMS , en cliquant en haut a droite sur l’icone correspondant .",
          manage_code_create_title: 'Gestion des codes :  Création',
          manage_code_create_msg:
            "Fonctionnalité de niveau 'Administrateur entité'.\n\nPour créer un code, cliquer sur le bouton 'Création code'. Renseigner ensuite tous les champs marqués d'un '*'.\n\n La date selectionnée est incluse dans la plage de validité mais n'est pas la date de départ. Dans tous cas la durée ne pourra pas exceder le 31 décembre de l’année en cours. Au 1er janvier tous les codes seront périmés, il faudra alors retourner dans cette page pour recréer les codes d’acces .\n\n SMS et Email sont optionnels, leurs envois se font immédiatement après la création du code, à l'utilisateur sélectionné. La description est optionnelle, le champ libre.\n\n N’oublier pas de cliquer sur 'Créer' pour valider la création du code.",
          manage_code_title: 'Gestion des codes : Historique',
          manage_code_msg:
            "Fonctionnalité de niveau 'Administrateur entité'.\n\nPour consulter les codes que vous avez crée en tant qu'administrateur d'une entité, cliquer sur le bouton 'Historique codes'.\n\n Dans cette page, les codes sont séparés en 2 listes, regroupant les codes en cours de validité et ceux expirés ou en attente de validité. Cliquer sur le bouton 'détails' de chaque ligne pour obtenir des détails.\n\n Veuillez noter qu'il n'est pas possible de voir le code en lui-même pour les codes dont vous n'êtes pas l'utilisateur cible .",
          manage_user_title: 'Gestion des utilisateurs',
          manage_user_msg:
            "Fonctionnalité de niveau 'Administrateur entité'.\n\n Pour gérer les utilisateurs, cliquer sur 'Utilisateurs' dans la barre du navigateur. Cette page contient la liste de tous les utilisateurs de l'entité. Vous pouvez cliquer sur son identifiant pour obtenir plus de détails sur un utilisateur.\n\n Depuis la page de détails, vous pouvez désactiver l'utilisateur de manière à lui interdire l'accès à l'application, sans supprimer son compte. La page de modification permet la modification de tous les paramètres du compte utilisateur (N’oubliez pas d’enregistrer les changements en cliquant sur la touche 'enregistrer' en bas à gauche ). \n\n Pour la creation d'un nouvel utilisateur : Vous devez renseigner les différents champs :\n\n• Entrer l’email de l’utilisateur , il servira pour envoyer le code d’accès et l’identifiant à l’utilisateur  \n• Entrer le numéro de téléphone : il servira a l’envoi du code d’accès et de l’identifiant à l’utilisateur .\n• Entrer le niveau d’accès :\n --> Utilisateur : l’accès est validé seulement en tant qu’utilisateur, seul la visibilité du code et de l’identifiant pour lui-même sont accessibles .\n --> Administrateur entité : l’accès est validé pour la création et la validité du code d’accès et de l’identifiant de tous les utilisateurs y compris lui-même, mais seulement pour son entité .\n\n• Descriptif : champ destiné à mettre un commentaire.",
          what_entity_title: "Qu'est ce qu'une entité ?",
          what_entity_msg:
            "Une entité represente une organisation, de type entreprise. Les droits d'accès et donc l'utilisation sont cloisonnés par entité",
          code_not_working_title: 'Que faire si mon code ne fonctionne pas ?',
          code_not_working_msg:
            ' - Vérifier que votre code est toujours valide (date début et fin)\n\n - Vérifier le système est à la bonne date (page principale / page réglage utilisateur)\n\n - Vérifier que la version est supérieure ou égale à la version 03.01.00', //CGU
          gen_presentation_title: 'Presentation générale',
          gen_presentation_msg:
            'Grace à une page Web, un identifiant et un mot de passe, les installeurs seront en mesure de générer des codes d’accès au MC603 ou de récupérer leur code d’accès MC603 sans l’aide d’ASCOREL.\n\nPour cela il faut au préalable qu’ASCOREL crée un administrateur auprès de l’entité client .\n\nCe sera donc cette personne qui pourra créer des utilisateurs et des mots de passe associés pour sa propre entité.',
          faq_connexion_title: 'Se connecter',
          faq_connexion_msg_1:
            '•	Entrer l’identifiant (numérique)\n\n•	Entrer le mot de passe (5 caractères alphanumériques)\n\n•	Appuyer sur login \n\n•	Si l’identifiant ou le mot de passe ne sont pas valides, alors un message d’erreur apparait en haut à droite « Invalid credentials »',
          faq_connexion_msg_2:
            'Si l’identifiant et le mot de passe sont valides alors vous êtes redirigé sur votre espace « TABLEAU DE BORD ».',
          dashboard_title: 'Votre espace',
          dashboard_msg:
            '• Choix de la langue : Pour l’instant seul le choix entre le francais et l’anglais sont disponibles . il suffit de cliquer sur la zone et de choisir dans le menu déroulant la langue choisie .\n\n• Nom et version du logiciel : Si vous cliquez dessus depuis n’importe quel page , cela vous ramene à la page du Tableau de bord \n\n• Votre profil : C’est ici que vous pouvez changer votre profil ',
          //CGU
          cgu_title: "Conditions générales d'utilisation",
          cgu_lead: "Contrat d'adhésion",
          return_my_account: 'Retour Mon compte',
          consultCGU:
            " Consulter les <1>conditions générales d'utilisation</1>",
          data_prot_title: 'Politique de protection des données personnelles',
          data_prot_subtitle1: '1. Les données collectées',
          data_prot_subtitle2: '2. Qu’est-ce qu’une donnée personnelle ?',
          data_prot_subtitle3: '3. Quelles données sont collectées ?',
          data_prot_subtitle4: '4. Les raisons de la collecte de ces données ?',
          data_prot_subtitle5:
            '5. De quelle manière les données sont-elles collectées ?',
          data_prot_subtitle6:
            '6. Durée de conservation des données personnelles ?',
          data_prot_subtitle7:
            '7. Les droits des titulaires des données personnelles',
          data_prot_subtitle8: '8. Modifications de cette Politique',
          data_prot_subtitle9:
            '9. Protection et partage des données personnelles',
          data_prot_text_1:
            'Le présent document détaille la façon dont vos données sont protégées. Il s’agit des données personnelles collectées et traitées par la société ASCOTRONICS S.A.S. dont le siège social est situé : \n\n357 Rue du champ de course\n38780 PONT-EVEQUE\nFRANCE.',
          data_prot_text_2:
            'Les « données à caractère personnel » désignent toute information susceptible de vous identifier directement (par ex., votre nom) ou indirectement (par ex., par le biais de données pseudonymisées tel qu’un identifiant unique). Cela signifie que les données à caractère personnel incluent des informations telles que des adresses de messagerie, noms d’utilisateur, des contenus générés par des utilisateurs, le sexe, la tranche d’âge, le lieu d’origine, le profil d’utilisateur (alternant, collaborateur d’entreprise, candidat individuel…). Les données à caractère personnel sont également susceptibles d’inclure des identifiants numériques uniques comme l’adresse IP de votre ordinateur ou l’adresse MAC de votre appareil mobile, ainsi que des cookies. ',
          data_prot_text_3:
            'ASCOTRONICS collecte et gère les informations ci-après dans le cadre de son activité de suivi d’équipements : \n\nLes informations communiquées par les utilisateurs de la plateforme inscrits sur le site : \n\n•	Nom et prénom de l’utilisateur \n•	Adresse email de l’apprenant (professionnelle et/ou personnelle selon le cas)\n•	Organisation auquel l’utilisateur appartient (société, établissement d’enseignement, etc…)\n•	Pays d’origine',
          data_prot_text_4:
            'Nous collectons ces données personnelles dans le cadre de notre activité de suivi d’équipement notamment pour les finalités suivantes : \n\n1.	Fournir à nos utilisateurs les prestations de suivi de l’équipement ;\n2.	Suivre et gérer les données générées par l’équipement au cours de son utilisation ; \n3.	Permettre de transmettre ces informations à un service technique et/ou d’assistance inclus sur la plateforme ou tiers à la plateforme ; \n4.	Recueillir vos commentaires et avis dans le cadre d’enquêtes statistiques ; \n\nNous pouvons collecter des données personnelles par le biais de cookies afin par exemple d’améliorer votre navigation sur notre Site ou mesurer son audience. Pour de plus amples informations, merci de consulter notre Politique d’utilisation des cookies. ',
          data_prot_text_5:
            'Vos données personnelles sont collectées de la manière suivante :\n\nLors de votre navigation sur notre/nos site(s) internet.',
          data_prot_text_6:
            'Nous mettons en œuvre des politiques de protection des systèmes d’information adaptées à la nature des données traitées et à son activité, conformément à la règlementation en vigueur. \n\nEn notre qualité de responsable de traitement des données personnelles et conformément aux règles nationales et européennes relatives à la protection des données personnelles (et notamment le Règlement Général sur la Protection des Données) nous apportons une vigilance extrême concernant le partage des données personnelles avec des tiers. \n\nLes données ne sont partagées qu’en tant que de besoin avec les sous-traitants (notamment son prestataire d’hébergement informatique), les services tiers de support technique sur les équipements connecté à la plateforme. ',
          data_prot_text_7:
            'Les données à caractère personnel sont conservées pendant une durée limitée qui n’excède pas la durée nécessaire aux finalités du traitement, cette durée n’excède pas 3 ans après la fin de la dernière connexion à la plateforme. \n\nLes délais de conservation des données sont portés à la connaissance des titulaires des données, et varient selon la nature des données, la finalité des traitements, ou les exigences légales ou réglementaires. ',
          data_prot_text_8:
            "Conformément à la règlementation en vigueur, les titulaires des données personnelles disposent d’un droit d'accès, d’information sur la finalité du traitement, de limitation, de suppression, de portabilité, de rectification et d'opposition au traitement de leurs données personnelles. \n\nLe titulaire des données peut exercer ces droits en contactant Ascotronics : \n\n•	Par email : dpo-rgpd@ascotronics.com\n•	Par courrier : \n\nASCOTRONICS\nDPO-Référent RGPD\n357 Rue du champ de course\n38780 PONT-EVEQUE - FRANCE\n\nEnfin, vous pouvez introduire une réclamation auprès d’une autorité de protection des données personnelles dans l’Etat membre de l’Union européenne dans lequel vous résidez, dans celui dans lequel vous travaillez ou dans celui où un manquement au droit de la protection des données personnelles a eu lieu. ",
          data_prot_text_9:
            'La présente politique de protection des données sera actualisée en tant que de besoin. ',
          mentions_legales_title: 'Mentions légales',
          mentions_legales_subtitle1: '1. Informations éditeur',
          mentions_legales_subtitle2: '2. Informations hébergeur :',
          mentions_legales_subtitle3: '3. Crédits',
          mentions_legales_text_1_1:
            'La présente Plateforme de Service disponible à l’URL acg.ascorel.com est éditée par la société ASCOTRONICS S.A.S. représentée par Francis Pierre LEMAHIEU en sa qualité de président \n\nSiège social :\n\nRue du Champs de course\nZone Industrielle Montplaisir\n38780 Pont-Evèque\nFRANCE',
          mentions_legales_text_1_2:
            '\nNuméro SIREN: 441 607025\nN° TVA intracommunautaire : FR 34 441 607 025\n\nContact : ',
          mentions_legales_text_1_3:
            '\n•	Téléphone: +33 (0)4 74 57 62 63\n•	Adresse électronique : mail-informatique@ascorel.com',
          mentions_legales_text_1_4:
            'Directeur de publication : Francis Pierre LEMAHIEU',
          mentions_legales_text_2:
            'Nom : Heroku, Inc\n\nAdresse : Heroku, Inc., 1 Market St. Suite 300, San Francisco, CA 94105',
          mentions_legales_text_3:
            'Conception et réalisation de la plateforme : ASCOREL S.A. \n\nHébergement : Heroku, Inc\n\nPhotos : Les photos et descriptifs sont non contractuels et donnés à titre indicatif. \n\nAscotronics se réserve le droit de les modifier sans préavis.\n\nLa présente Plateforme et ses mentions légales sont soumises au droit français. ',
          pol_confidentialite_title: 'Politique de confidentialité',
          pol_confidentialite_subtitle1:
            '1. Données personnelles pouvant être collectées',
          pol_confidentialite_subtitle2:
            '2. Utilisations de vos données personnelles',
          pol_confidentialite_subtitle3: '3. Divulgation de vos données',
          pol_confidentialite_subtitle4: '4. Stockage de vos données',
          pol_confidentialite_subtitle5: '5. Vos droits',
          pol_confidentialite_subtitle6: '6. Utilisation des cookies',
          pol_confidentialite_subtitle7:
            '7. Changements à notre politique de confidentialité',
          pol_confidentialite_subtitle8: '8. Contact',
          pol_confidentialite_text_1_1:
            'La plateforme de service (abrégée ci-dessous en PFS) peut collecter et traiter les données suivantes vous concernant. ',
          pol_confidentialite_text_1_XX:
            'Des données d’identification directe, dont certaines sont obligatoires et d’autres facultatives (données de profil que vous décidez de renseigner ou non). ',
          pol_confidentialite_text_1_2: 'Données obligatoires :',
          pol_confidentialite_text_1_3:
            '•	Identifiant\n•	Prénom\n•	Nom\n•	Adresse de courriel\n•	Mot de passe\n•	Pays',
          pol_confidentialite_text_1_4:
            'Des données liées à l’utilisation propre de l’application (Outil électronique connecté à la plateforme, données de mesures, rapport de diagnostic, …). Ce sont les données de tracking sur les ressources mises à disposition dans la PFS. \n\nInformations détaillées d’équipement électronique : liste des outils utilisés par l’utilisateur et les données associés à cet équipement ',
          pol_confidentialite_text_1_5: 'Rapports :',
          pol_confidentialite_text_1_6:
            '•	Toute l’activité des équipements électroniques connectés à la PFS. \n•	Toutes les activités liées aux données remontées par les équipements connectés à la PFS. ',
          pol_confidentialite_text_1_7: 'Informations de connexion :',
          pol_confidentialite_text_1_8:
            "La plateforme collecte les dates de premier et dernier accès et les affiche en clair aux utilisateurs. Elle trace également dans des tables, accessibles aux gestionnaires (si l'accès est autorisé par l'Administrateur) tous les logins de chaque utilisateur (date, heure, durée de connexion s'ils se déconnectent). ",
          pol_confidentialite_text_1_9: 'Divers :',
          pol_confidentialite_text_1_10:
            'La PFS collecte tous les messages publiés sur le site.',
          pol_confidentialite_text_2:
            "Vos données ne seront utilisées qu'aux fins utiles au sites de la PFS pour : \n\n•	Vous permettre d’accéder à l’ensemble des ressources mises à votre disposition \n•	Nous permettre de suivre votre activité et celle des équipements connectés à la PFS. \n•	Transmettre les informations à des organismes dont l’utilisateur dépends sur la PFS : employeur, fournisseur de l’outil de diagnostic de la marque, support technique de l’équipement technique. ",
          pol_confidentialite_text_3:
            "Nous ne communiquerons aucune de vos données à des tiers sans votre permission, sans votre consentement sur un formulaire, ou sauf indication contraire dans la présente charte de confidentialité. \n\nNous nous réservons la possibilité de divulguer vos informations personnelles à des tiers, sans obtenir votre consentement, dans les cas suivants : \n\n•	Si nous sommes dans l'obligation de divulguer ou de partager vos données personnelles afin de respecter toute obligation légale ou de protéger nos droits, nos biens ou la sécurité de nos employés, de nos clients ou d'autres personnes. Cela comprend l'échange d'informations avec d'autres sociétés et organisations à des fins de protection contre la fraude. \n\nNous nous engageons à ne jamais vendre vos données à des tiers, à des fins de marketing. ",
          pol_confidentialite_text_4:
            "Les données que nous collectons auprès de vous peuvent être transférées et stockées dans un lieu situé en dehors de la France, mais toujours dans l’Union Européenne. \n\nA notre niveau, vos données sont traitées dans l'UE (en France, au moment de la rédaction). Au niveau de nos partenaires, le traitement des données peut également avoir lieu en dehors de l'Europe, dans les limites légales.\n\nNous pouvons conserver dans nos archives certaines des informations associées à votre compte, notamment à des fins de statistiques sur l’activité sur la PFS. La durée de conservation de vos données personnelles dépend de la finalité pour laquelle nous les collectons et de l'utilisation que nous en faisons. \n\nNous ne conserverons vos données personnelles que pendant la durée strictement nécessaire aux finalités énoncées dans le paragraphe « Utilisation de vos données personnelles » ou pour satisfaire à nos obligations légales. La période de conservation de vos données personnelles est de 3 ans à compter de la date de votre dernière activité. Nous pouvons conserver certaines données personnelles pendant une durée plus longue, y compris après la fermeture de votre compte, si une telle mesure est nécessaire pour remplir nos obligations légales ou pour maintenir la sécurité, pour empêcher la fraude et les abus et pour défendre ou faire valoir nos droits. ",
          pol_confidentialite_text_5:
            "Vous avez le droit d'accéder à vos données personnelles, de les modifier et de les supprimer. \n\nSi vous souhaitez demander une copie de vos données personnelles, la suppression de vos données personnelles ou si vous avez toute question liée à ces données, vous pouvez contacter ASCOTRONICS S.A.S via l’adresse email suivante : dpo-rgpd@ascotronics.com",
          pol_confidentialite_text_6:
            "La plateforme utilise des cookies dans les cas suivants :\n\n•	Nous pouvons collecter des informations sur votre ordinateur, y compris votre adresse IP, système d'exploitation et type de navigateur, pour l'administration du système et le bon fonctionnement de celui-ci. En aucun cas ces données collectées par les cookies ne seront transmises. \n•	Si des liens vers les réseaux sociaux sont présents sur la plateforme, et afin de rendre le « partage social » accessible, les cookies des réseaux sociaux sont présents : les cookies des réseaux sociaux varient et dépendent des paramètres de confidentialité propres à chaque réseau social. Les boutons « Partage social » ne déploieront des cookies que si vous êtes connecté au dit réseau social au moment de votre visite sur notre site web. \n\nUne rubrique dédiée à l’acceptation des cookies figure dans la politique de confidentialité, et doit faire l’objet d’acceptation à la première connexion. \n\nLes informations d’acceptation sont stockées et conservées dans la PFS, accessibles pour le gestionnaire. \n\nComment désactiver les cookies ?\nLa plupart des navigateurs modernes vous permettent de contrôler vos paramètres de cookies pour tous les sites web que vous visitez. Vous pouvez désactiver complètement l’utilisation des cookies en modifiant les paramètres de votre navigateur. Cependant, vous risquez de limiter les fonctionnalités affichées sur notre site web. ",
          pol_confidentialite_text_7:
            'Tout changement que nous pourrions apporter à notre politique de confidentialité dans le futur sera publié sur cette page. En continuant à utiliser la plateforme, vous acceptez ces changements. ',
          pol_confidentialite_text_8_1:
            'Les demandes et commentaires relatifs à cette politique de confidentialité sont les bienvenus et doivent être envoyés à : ',
          pol_confidentialite_text_8_2:
            'ASCOTRONICS\nDPO - Référent RGPD\n357 Rue du champ de courses\n38780 PONT-EVEQUE - FRANCE'
        }
      },
      en: {
        translation: {
          titre_app: 'Access code generator',
          msg_version_min:
            'This code can be used on MC603 with a firmware version higher than or equal to 03.01.00',
          codes: 'Codes',
          users: 'Users',
          entities: 'Entities',
          my_account: 'My account',
          log: 'Log',
          logout: 'Logout',
          unauthorized_access: 'Unauthorized access',
          cancel: 'Cancel',
          // Landing
          landing_title: 'ASCOREL access code generator',
          landing_lead:
            ' welcome, please log in to get access to all functionalities',
          //Dashboard
          dashboard: 'Dashboard',
          code_lead: 'Generate and manages codes',
          code_lead_user: 'Display your codes',
          users_lead: 'Users management',
          entities_lead: 'Entities management',
          //Codes
          assigned_codes_title: 'Assigned codes',
          assigned_codes_lead: 'Codes which you are the user',
          created_codes_title: 'Created codes history',
          created_codes_lead: 'Codes you created',
          code_history: 'Codes history',
          code_create: 'Code create',
          code_quick: 'Quick code',
          valid_codes: 'Valid codes',
          invalid_codes: 'Invalid codes',
          old_versions_codes: 'For software versions older than 03.02.xx',
          title_old_version_1: 'For versions older than 03.00.xx',
          title_old_version_2: 'For versions between 03.00.xx and 03.02.xx',
          code_cree_par: 'Created by',
          code_cree_pour: 'Created for',
          code_produit: 'Product',
          code_date_debut: 'Start date',
          code_date_fin: 'End date',
          code_date_create: 'Creation date',
          code_duration: 'Duration',
          code_level: 'Level',
          level_1: '1 - Visualization',
          level_2: '2',
          level_3: '3',
          level_4: '4 - Technician',
          level_5: '5',
          level_6: '6',
          level_7: '7',
          level_8: '8 - Projet manager',
          level_9: '9',
          level_10: '10',
          level_11: '11',
          level_12: '12',
          level_13: '13',
          level_14: '14 - Ascorel Technician',
          level_15: '15 - After-Sales Service Ascorel',
          level_16: '16 - Ascorel R & D',
          code_state: 'State',
          code_desc: 'Description',
          code_not_found_valid:
            'No valid code found. Please contact your administrator to generate a valid code.',
          code_not_found: 'No code found',
          return_assigned_code: 'Back to assigned codes',
          waiting: 'Waiting',
          expired: 'Expired',
          valid: 'Valid',
          unknown: '???',
          niveau_small: '* Code authorization level',
          //Code detail
          back_to_code_list: 'Back to code list',
          deleted_code: 'Delete code',
          assigned_to: ' Assigned to',
          resend_email: 'Send email',
          resend_sms: 'Send SMS',
          //Code create
          utilisateur_code: 'Code user',
          creer_code_title: 'Create a new code',
          quick_code_title: 'Quick code',
          creer_code_lead: ' Please complete the following fields :',
          quick_code_lead: ' Generate a new daily code for yourself :',
          champs_requis: '* = required',
          target_user_expl:
            '* Choose which user you want to assign the code to',
          product_select: 'Select product',
          product_expl: '* Product type',
          start_date_expl: '* Start date',
          duration_expl: '* code validity period',
          descri_expl: 'Code description',
          sms_send: 'Send by SMS',
          email_send: 'Send by email',
          select_duration: 'Select duration',
          day: 'Day',
          week: 'Week',
          month: 'Month',
          trimester: 'Trimester',
          semester: 'Semester',
          year: 'Year',
          create: 'Create',
          activation_email_sent:
            'An activation email with a validity period is 7 days will soon be sent to the user',
          //User list
          accounts: 'Accounts',
          accounts_manag: 'Accounts management',
          user_create: 'New account',
          id: 'ID',
          email: 'Email',
          phone: 'Phone',
          descri: 'Description',
          rights: 'Rights',
          entity: 'Entity',
          modify: 'Modified',
          last_seen: 'Last seen',
          no_users_found: 'No users found',
          activated: 'activated',
          deactivated: 'deactivated',
          all_header: 'All',
          //User detail
          back_dasboard: 'Back to dashboard',
          back_acc_list: 'back to accounts list',
          modify_account: 'Modify account',
          deactivate_account: 'Deactivate account',
          reactivate_account: 'Reactivate account',
          delete_account: 'Delete account',
          account_deactivated: 'Account DEACTIVATED',
          activate_msg: 'This will activate the account. Continue ?',
          deactivate_msg: 'This will deactivate the account. Continue ?',
          //User edit
          password_match_err: "Passwords don't match",
          password_6_error: 'Password should be at least 6 characters long',
          create_account_title: 'Create a new account',
          mod_account_title: 'Modify an account',
          mod_account_lead: ' Modifying account parameters :',
          must_unique: 'Must be unique',
          ph_id: '*ID',
          ph_email: '*Email',
          phone_hint: 'Add 0033 and remove the first 0, ex:0033651025511',
          phone_ph: '*Phone number',
          rights_hint: 'Access level',
          rights_select: '* Select rights',
          rights_admin: 'Admin - Ascorel experts only',
          rights_admin_ent: 'Entity admin',
          rights_user: 'User',
          entity_hint: 'Organisation',
          entity_select: '* Select entity',
          name_hint: 'Please choose a descriptive name',
          name_ph: 'Description',
          pass_hint: 'Minimum 6 characters',
          pass_ph: 'Modify password',
          pass2_hint: 'Repeat new password for confirmation',
          pass2_ph: 'Confirm new password',
          save: 'Save',
          create: 'Create',
          //login
          login_lead: ' Please enter your login details',
          id_ph: 'ID',
          pw_ph: 'Password',
          pw_fg: 'Forgot your password ?',
          pw_fg1: 'Click here',
          //Not found
          page_not_found: '  Page not found',
          error_404: 'Its seems an error occured',
          try_back: 'Please try back later',
          //Reset password
          reset_pw_title1:
            'Please enter your new password and accept the general conditions of use to activate your account',
          reset_pw_title2: 'Please enter your new password',
          reset_pw_pw1: 'New password, minimum 6 characters',
          reset_pw_pw2: 'Repeat new password',
          success_account: 'Account activated, you can close this page',
          success_pw: 'Password reinitialised, you can close this page',
          acceptCGU: 'I accept the <1>general conditions of use</1>',
          acceptCGUWarning:
            'Please accept the general conditions of use to activate your account',
          passwordWarning:
            'Passwords should be identicals and contain at least 6 characters',
          //Verify email
          ver_email_title1: 'Forgot your password ?',
          ver_email_title2:
            'Please enter your email address to receive a link for resetting your password',
          ver_email_hint: 'Please use the email address linked to your account',
          ver_email_done:
            'Email will be sent in a few minutes. Please check your spam folder if you did not receive it',
          send: 'Send',
          //Entity list
          entities_mngt: ' Entities management',
          entity_create: ' New entity',
          name: 'Name',
          lvl_max_admin: 'Max level entity admin',
          lvl_max_user: 'Max level user',
          no_entity_found: 'No entity found',
          //Entity list
          back_list_ent: ' Back to entities list',
          modify_entity: ' Modify entity ',
          del_entity: ' Delete entity ',
          //Entity Create
          creat_entity_lvl: 'Create a new entity',
          modify_entity_lvl: '  Modify entity parameters :',
          select_admin_lvl: '* Select access level of entity admin',
          admin_lvl_hint: 'Maximum access level for entity admins',
          select_user_lvl: '* Select access level of user',
          user_lvl_hint: 'Maximum access level for users',
          //FAQ :
          faq_lead: 'Access code generator frequently asked questions',
          faq_quick_code_title: 'Understanding Quick code',
          quick_code_msg:
            "'User' level functionality.\n\n'Quick code' button on the 'Codes' main page allows you to generate daily codes for personal use only. Selected day needs to be part of the current week.",
          consult_code_title: 'How to check my codes?',
          consult_code_msg:
            "To view your assigned codes, click on 'Codes' in the navigation bar at the top of the page. On this page, codes are separated into 2 lists, one listing all currently valid codes and one those expired or awaiting validity.\n\n Click on the 'details' button of each line to obtain the code and identifier required to enter calibration on the target system.\n\n You can send it to yourself by SMS or/and email, by clicking the corresponding button in the top right corner",
          manage_code_create_title: 'Codes management :  Creation',
          manage_code_create_msg:
            "'Entity administrator' level functionality.\n\nTo create a code, click on the 'Create code' button. Then fill in all fields marked with an '*'. Selected date is included in the validity range but is not the starting date. In any cases, the end date cannot go beyond december 31 of the current year. On January 1st of next year, all codes will be expired and you will have to re-create them using the same page.\n\n SMS and Email are optional, they are sent immediately after the creation of the code to the target user. Description is optional and it's content unrestricted.\n\n Don't forget to press 'Create' to validate the code's creation.\n\n To create a new user, you need to fill the following fields : \n\n•User's email : it will be used to send the generated access codes and id  \n• Phone number : it will be used to send the generated access codes and id by SMS  \n• Access level :\n --> User : reading access level, and only for his assigned codes.  \n --> Entity administrator : Can create code and users, but only for his own entity.\n\n• Description : can be used for commentary.",
          manage_code_title: 'Codes management :  History',
          manage_code_msg:
            "'Entity administrator' level functionality.\n\nTo view the codes you have created as an entity administrator, click on the 'Code history' button.\n\n On this page, the codes are separated in 2 lists, one listing all currently valid codes and one those expired or awaiting validity. Click on the 'details' button on each line to get details.\n\n Please note that it is not possible to see the code itself when you are not the target user .",
          manage_user_title: 'Users management',
          manage_user_msg:
            "'Entity Administrator' level functionality.\n\n To manage users, click on 'Users' in the navigation bar. This page contains the list of all users of the entity. You can click on their identifier to get more details.\n\n From the details page, you can deactivate the user , thus preventing them to access the application, without deleting their account. The modification page allows the modification of all parameters of the user account (don't forget to press 'Save' before exiting the page)",
          what_entity_title: 'What is an entity ?',
          what_entity_msg:
            'An entity stands for an organization, of the business type. Access rights and therefore use of the code generator are partitioned by entity',
          code_not_working_title: 'My code is not working',
          code_not_working_msg:
            " - Check that your code is still valid (start and end dates) \n\n - Check that your system date is correct (main page/user settings page)\n\n - Check that you system's version is higher than or equal to version 03.01.00",

          gen_presentation_title: 'General introduction',
          gen_presentation_msg:
            'With a web page, an id and a password, installers will be able to generate MC603 access code or to get their codes without Ascorel personnel help.\n\n  The first step will be to have at least one entity administrator account for your entity.\n\n This person will then be able to create user accounts and associated codes for his own entity',
          faq_connexion_title: 'Login in',
          faq_connexion_msg_1:
            '•	Enter you id\n\n•	Enter the password (5 characters)\n\n•	Press "Login" \n\n•	If the ID or password are invalid, an error message will appear on the top right corner of the page : « Invalid credentials »',
          faq_connexion_msg_2:
            'If ID and passwords are both corrects, you will be redirected to your "Dashboard" page',
          dashboard_title: 'Your space',
          dashboard_msg:
            '• Language choice : For now, only English and French can be selected by cliking the top right zone and selecting the right section.\n\n• Name and software version : by clicking here from anywhere, you will be redirected to you dashboard \n\n• Your profile : Your profile can be managed here. ',
          //CGU
          cgu_title: 'General Conditions of Use',
          cgu_lead: 'Contract of adhesion',
          return_my_account: 'Back to my account',
          consultCGU: ' Consult the <1>General Conditions of Use</1>',
          data_prot_title: 'Personal data protection policy',
          data_prot_subtitle1: '1. Data collected',
          data_prot_subtitle2: '2. What is personal data?',
          data_prot_subtitle3: '3. What data is collected?',
          data_prot_subtitle4: '4. The reasons for collecting this data?',
          data_prot_subtitle5: '5. How is the data collected?',
          data_prot_subtitle6: '6. Duration of retention of personal data?',
          data_prot_subtitle7: '7. Rights of personal data holders',
          data_prot_subtitle8: '8. Changes to this Policy',
          data_prot_subtitle9: '9. Protection and sharing of personal data',
          data_prot_text_1:
            'This document details how your data is protected. These are personal data collected and processed by the company ASCOTRONICS S.A.S. whose head office is located: \n\n357 Rue du champ de course\n38780 PONT-EVEQUE\nFRANCE.',
          data_prot_text_2:
            "“Personal data” means any information that can identify you directly (e.g. your name) or indirectly (e.g. through pseudonymized data such as a unique identifier). This means that personal data includes information such as email addresses, usernames, user-generated content, gender, age group, place of origin, user profile (work-study student, company employee, individual candidate, etc.). Personal data may also include unique numerical identifiers such as your computer's IP address or your mobile device's MAC address, as well as cookies. ",
          data_prot_text_3:
            "ASCOTRONICS collects and manages the following information as part of its equipment monitoring activity: \n\nThe information communicated by the users of the platform registered on the site: \n\n•	User's first and last name\n•	Learner's email address (professional and/or personal as appropriate)\n•	Organization to which the user belongs (company, educational institution, etc.) \n•	Native country",
          data_prot_text_4:
            'We collect this personal data as part of our equipment tracking activity, in particular for the following purposes: \n\n1.	Provide our users with equipment tracking services;\n2.	Track and manage the data generated by the equipment during its use;\n3.	Allow this information to be transmitted to a technical and/or support service included on the platform or third parties to the platform; \n4.	Collect your comments and opinions as part of statistical surveys;\n\nWe may collect personal data through cookies in order, for example, to improve your navigation on our Site or measure its audience. For more information, please see our Cookies Policy. ',
          data_prot_text_5:
            'Your personal data is collected as follows:\n\nWhen browsing our website(s).',
          data_prot_text_6:
            'Personal data is kept for a limited period which does not exceed the period necessary for the purposes of the processing, this period does not exceed 3 years after the end of the last connection to the platform. \n\nThe data retention periods are brought to the attention of the data holders, and vary according to the nature of the data, the purpose of the processing, or legal or regulatory requirements. ',
          data_prot_text_7:
            'In accordance with the regulations in force, the holders of personal data have a right of access, information on the purpose of the processing, limitation, deletion, portability, rectification and opposition to the processing of their data. personal. \n\nThe data holder may exercise these rights by contacting Ascotronics: \n\n•	By email: dpo-rgpd@ascotronics.com\n•	By mail: \n\nASCOTRONICS\nDPO- Privacy policy referent\n357 Rue du champs de course\n38780 PONT-EVEQUE - FRANCE\n\nFinally, you can lodge a complaint with a personal data protection authority in the Member State of the European Union in which you reside, in the one in which you work or in the one where a breach of data protection law personal data has taken place. ',
          data_prot_text_8:
            'This data protection policy will be updated as necessary.',
          data_prot_text_9:
            'We implement information systems protection policies adapted to the nature of the data processed and its activity, in accordance with the regulations in force. \n\nIn our capacity as personal data processing manager and in accordance with national and European rules relating to the protection of personal data (and in particular the General Data Protection Regulations) we exercise extreme vigilance regarding the sharing of personal data with third parties. . \n\nThe data is only shared as needed with subcontractors (in particular its IT hosting provider), third-party technical support services on equipment connected to the platform. ',
          mentions_legales_title: 'Legal Notice',
          mentions_legales_subtitle1: '1. Publisher information',
          mentions_legales_subtitle2: '2. Host information:',
          mentions_legales_subtitle3: '3. Credits',
          mentions_legales_text_1_1:
            'This Service Platform available at the URL acg.ascorel.com is published by the company ASCOTRONICS S.A.S. represented by Francis Pierre LEMAHIEU in his capacity as president \n\nHead office: \n\nRue du Champs de course\nZone Industrielle Montplaisir\n38780 Pont-Evèque\nFRANCE',
          mentions_legales_text_1_2:
            '\nSIREN number: 441 607025\n\nIntra-community VAT number: FR 34 441 607 025\n\nContact :',
          mentions_legales_text_1_3:
            '•	Phone: +33 (0)4 74 57 62 63\n•	Email address: mail-informatique@ascorel.com',
          mentions_legales_text_1_4:
            'Director of publication: Francis Pierre LEMAHIEU',
          mentions_legales_text_2:
            'Name : Heroku, Inc\n\nAddress : Heroku, Inc., 1 Market St. Suite 300, San Francisco, CA 94105',
          mentions_legales_text_3:
            'Design and production of the platform: ASCOREL S.A\n\nHosting: Heroku, Inc \n\nPhotos: The photos and descriptions are non-contractual and given for information only. \n\nAscotronics reserves the right to modify them without notice.\n\nThis Platform and its legal notices are subject to French law.',
          pol_confidentialite_title: 'Privacy policy',
          pol_confidentialite_subtitle1:
            '1. Personal data that may be collected',
          pol_confidentialite_subtitle2: '2. Uses of your personal data',
          pol_confidentialite_subtitle3: '3. Disclosure of your data',
          pol_confidentialite_subtitle4: '4. Storage of your data',
          pol_confidentialite_subtitle5: '5. Your rights',
          pol_confidentialite_subtitle6: '6. Use of cookies',
          pol_confidentialite_subtitle7: '7. Changes to our privacy policy',
          pol_confidentialite_subtitle8: '8. Contact',
          pol_confidentialite_text_1_1:
            'The service platform (abbreviated below as PFS) may collect and process the following data about you. ',
          pol_confidentialite_text_1_XX:
            'Direct identification data, some of which are mandatory and others optional (profile data that you decide to fill in or not). ',
          pol_confidentialite_text_1_2: 'Mandatory data:',
          pol_confidentialite_text_1_3:
            '•	Username\n•	First name\n•	Name\n•	Email Address\n•	Password\n•	Country',
          pol_confidentialite_text_1_4:
            'Data related to the specific use of the application (electronic tool connected to the platform, measurement data, diagnostic report, etc.). These are the tracking data on the resources made available in the PFS. \n\nDetailed information of electronic equipment: list of tools used by the user and the data associated with this equipment ',
          pol_confidentialite_text_1_5: 'Reports  :',
          pol_confidentialite_text_1_6:
            '•	All electronic equipment activity connected to the PFS. \n•	All activities related to data reported by devices connected to the PFS. ',
          pol_confidentialite_text_1_7: 'Login information :',
          pol_confidentialite_text_1_8:
            'The platform collects the dates of first and last access and displays them in plain text to users. It also traces in tables, accessible to managers (if access is authorized by the Administrator) all the logins of each user (date, time, duration of connection if they disconnect). ',
          pol_confidentialite_text_1_9: 'Various :',
          pol_confidentialite_text_1_10:
            'The PFS collects all messages posted on the site.',
          pol_confidentialite_text_2:
            "Your data will only be used for purposes useful to the PFS sites for:\n\n•  Allow you to access all the resources made available to you\n•  Allow us to track your activity and that of devices connected to the PFS.\n•  Transmit the information to organizations on which the user depends on the PFS: employer, supplier of the brand's diagnostic tool, technical support for technical equipment. ",
          pol_confidentialite_text_3:
            'We will not communicate any of your data to third parties without your permission, without your consent on a form, or unless otherwise indicated in this privacy policy. \n\nWe reserve the right to disclose your personal information to third parties, without obtaining your consent, in the following cases: \n\n•  If we are under a duty to disclose or share your personal data in order to comply with any legal obligation or to protect our rights, property or the safety of our employees, customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection. \n\nWe promise never to sell your data to third parties for marketing purposes. ',
          pol_confidentialite_text_4:
            'The data we collect from you may be transferred and stored in a location outside of France, but still within the European Union. \n\nHosting and maintenance are carried out by a provider, Amazon Web Services\n\nAt our level, your data is processed in the EU (in France, at the time of writing). At the level of our partners, data processing can also take place outside Europe, within legal limits.\n\nWe may keep some of the information associated with your account in our archives, in particular for the purposes of statistics on activity on the PFS. The retention period of your personal data depends on the purpose for which we collect it and the use we make of it. \n\nWe will only keep your personal data for the time strictly necessary for the purposes set out in the paragraph “Use of your personal data” or to meet our legal obligations. The retention period for your personal data is 3 years from the date of your last activity. We may retain certain personal data for a longer period of time, including after your account is closed, if such action is necessary to fulfil our legal obligations or to maintain security, to prevent fraud and abuse and to defend or enforce assert our rights. ',
          pol_confidentialite_text_5:
            'You have the right to access, modify and delete your personal data.\n\nIf you wish to request a copy of your personal data, the deletion of your personal data or if you have any questions related to this data, you can contact ASCOTRONICS S.A.S via the following email address: dpo-rgpd@ascotronics.com',
          pol_confidentialite_text_6:
            'The platform uses cookies in the following cases:\n\n•  We may collect information about your computer, including your IP address, operating system and browser type, for system administration and proper functioning. Under no circumstances will the data collected by cookies be transmitted. \n•  If links to social networks are present on the platform, and in order to make “social sharing” accessible, social network cookies are present: social network cookies vary and depend on the privacy settings specific to each social network . The “Social sharing” buttons will only deploy cookies if you are connected to said social network at the time of your visit to our website. \n\nA section dedicated to the acceptance of cookies appears in the privacy policy, and must be accepted on the first connection. \n\nAcceptance information is stored and maintained in the PFS, accessible to the manager. \n\nHow to disable cookies?\n\nMost modern browsers allow you to control your cookie settings for all websites you visit. You can completely disable the use of cookies by changing your browser settings. However, you may limit the functionality displayed on our website. ',
          pol_confidentialite_text_7:
            'Any changes we may make to our privacy policy in the future will be posted on this page. By continuing to use the platform, you accept these changes. ',
          pol_confidentialite_text_8_1:
            'Inquiries and comments regarding this Privacy Policy are welcome and should be sent to: ',
          pol_confidentialite_text_8_2:
            'ASCOTRONICS S.A.S\nDPO- Privacy policy referent\n357 Rue du champ de courses\n38780 PONT-EVEQUE - FRANCE'
        }
      }
    }
  });

export default i18n;
