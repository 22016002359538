import {
  GET_ENTITY,
  GET_ENTITIES,
  ENTITY_ERROR,
  UPDATE_ENTITY,
  CLEAR_ENTITY
  // ENTITY_DELETED
} from '../actions/types';

const initialState = {
  entity: null,
  entities: [],
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ENTITY:
    case UPDATE_ENTITY:
      return {
        ...state,
        entity: payload,
        loading: false
      };
    case GET_ENTITIES:
      return {
        ...state,
        entities: payload,
        loading: false
      };
    case ENTITY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        entity: null,
        entities: []
      };
    case CLEAR_ENTITY:
      return {
        ...state,
        entity: null,
        entities: [],
        loading: false
      };
    default:
      return state;
  }
}
