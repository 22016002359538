import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import UserItem from './UserItem';
import { getUsers } from '../../actions/user';
import { FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const UserList = ({ auth, getUsers, user: { users, loading } }) => {
  useEffect(() => {
    getUsers();
  }, [getUsers]);
  const { t } = useTranslation();

  //Déclaration des hooks pour mettre à jour la liste des entités, la liste des droits et le tableau des utilisateurs.
  const [entitySelect, setEntitySelect] = useState('');
  const [rightsSelect, setRightsSelect] = useState('');
  const [stateSelect, setStateSelect] = useState('');
  const [filteredUsers, setFilteredUser] = useState([]);

  //Récupéreration des utilisateurs par rapport aux entités sélectionnées et aux droits sélectionnés.
  useEffect(() => {
    let filterUser = users;
    //Si la valeur n'est pas vide, alors on prend la variable filterUser et on filtre par rapport à l'entité sélectionnée, et on stocke la nouvelle liste dans la variable filterUser.
    if (entitySelect !== '') {
      filterUser = filterUser.filter((userr) => userr.entity === entitySelect);
    }

    //Si la valeur n'est pas vide, alors on prend la variable filterUser et on filtre par rapport au droit sélectionné, et on y stocke la nouvelle liste dans la variable filterUser.
    if (rightsSelect !== '') {
      filterUser = filterUser.filter((user) => user.rights === rightsSelect);
    }

    //Si la valeur de stateSelect est égale à true ou qu'elle est égale à false, on filtre l'utilisateur.
    if (stateSelect === 'true' || stateSelect === 'false') {
      //Ici, on filtre la liste filterUser en vérifiant si stateSelect est égal à true. Si c'est le cas, cela retournera true ; cependant, si c'est false, cela nous renverra false car la condition n'est pas vérifiée.
      filterUser = filterUser.filter(
        (use) => use.enabled === (stateSelect === 'true')
      );
    }

    //Ensuite, on renvoie la variable filterUser dans la fonction setFilteredUser.
    setFilteredUser(filterUser);
  }, [users, entitySelect, rightsSelect, stateSelect]);

  //Récupération de la liste des entités et tri par ordre alphabéthique
  const listEntity = users
    .reduce(
      (acc, ent) => (acc.includes(ent.entity) ? acc : acc.concat(ent.entity)),
      []
    )
    .sort();

  //Récupération de la liste des droits et tri par ordre alphabéthique
  const listRights = users
    .reduce(
      (acc, user) =>
        acc.includes(user.rights) ? acc : acc.concat(user.rights),
      []
    )
    .sort()
    .reverse();

  return (
    <section className="container">
      <Fragment>
        {loading || auth === null || users.length === 0 ? (
          <Spinner />
        ) : (
          <Fragment>
            {auth &&
            auth.user &&
            (auth.user.rights === 'admin' ||
              auth.user.rights === 'admin_entity') ? (
              <Fragment>
                <h1 className="large text-ascorel">{t('accounts')}</h1>
                <div className="parent-flex">
                  <p className="lead">
                    <FaUser
                      size={40}
                      color="grey"
                      style={{
                        marginRight: '1rem'
                      }}
                    />
                    {t('accounts_manag')}
                  </p>

                  <div className="child-left-auto">
                    <Link to="/create-user">
                      <button
                        className="btn btn-ascorel btn-lg"
                        style={{ marginRight: '0rem', marginBottom: '1rem' }}
                      >
                        {t('user_create')}
                      </button>
                    </Link>
                  </div>
                </div>
                <table
                  className="table text-nowrap table-striped table-light table-header-group table-container"
                  // border="1"
                >
                  <thead>
                    <tr className="sticky">
                      <th
                        className="text-center align-middle"
                        id="th1"
                        colSpan="1"
                      >
                        {/* On crée un menu déroulant avec la valeur par défaut de la variable stateSelect. Si l'utilisateur change l'option du menu, la nouvelle valeur est ensuite assignée à la fonction setStateSelect. */}
                        <select
                          className="form-select form-select-sm"
                          value={stateSelect}
                          onChange={(e) => setStateSelect(e.target.value)}
                        >
                          {/* Première option qui renvoie une valeur vide */}
                          <option value="">
                            {t('code_state')}: {t('all_header')}
                          </option>
                          {/* Deuxième option qui quand on la choisi renvoie la valeur true */}
                          <option value="true">
                            {t('code_state')}: {t('activated')}
                          </option>
                          {/* Troisième option, qui, quand on la choisit, renvoie la valeur false. */}
                          <option value="false">
                            {t('code_state')}: {t('deactivated')}
                          </option>
                        </select>
                      </th>
                      <th className="text-center align-middle" id="th4">
                        {t('email')}
                      </th>
                      {/* <th className="text-center hide-mobile" id="th3">
                          {t('email')}
                        </th> */}
                      {/* <th
                        className="text-center align-middle hide-mobile"
                        id="th8"
                      >
                        {t('phone')}
                      </th> */}
                      <th
                        className="text-center align-middle hide-mobile"
                        id="th2"
                      >
                        {t('descri')}
                      </th>
                      <th
                        className="text-center align-middle hide-mobile"
                        id="th5"
                      >
                        {/* On met un menu de selection avec la valeur par défault de la variable de rightsSelect, si le menu change d'option alors mettre la nouvelle valeur dans la fonction setRightsSelect */}
                        <select
                          className="form-select form-select-sm"
                          value={rightsSelect}
                          onChange={(e) => setRightsSelect(e.target.value)}
                        >
                          {/* première option qui affiche rights */}
                          <option value="" key="812">
                            {t('rights')}: {t('all_header')}
                          </option>
                          {/* Le fragment sert à afficher le reste des options qui ne sont pas fixes, car l'option précédente est fixe. */}
                          <Fragment>
                            {/* permet de de récupérer les différents droits et de les afficher dans les options */}
                            {listRights.map((nameRight, index) => (
                              <option
                                value={nameRight}
                                key={`${nameRight}-${index}`}
                              >
                                {t('rights')}: {nameRight}
                              </option>
                            ))}
                          </Fragment>
                        </select>
                      </th>
                      <th
                        className="text-center align-middle hide-mobile"
                        id="th6"
                      >
                        {/* On met un menu de selection avec la valeur par défault de la variable de entitySelect, si le menu change d'option alors mettre la nouvelle valeur dans la fonction setEntitySelect */}
                        <select
                          className="form-select form-select-sm"
                          value={entitySelect}
                          onChange={(event) =>
                            setEntitySelect(event.target.value)
                          }
                        >
                          {/* première option qui affiche entity */}
                          <option value="" key="415">
                            {t('entity')}: {t('all_header')}
                          </option>
                          <Fragment>
                            {/* permet de de récupérer les différents entités et de les afficher dans les options */}
                            {listEntity.map((nameEntity, index) => (
                              <option
                                value={nameEntity}
                                key={`${nameEntity}-${index}`}
                              >
                                {t('entity')}: {nameEntity}
                              </option>
                            ))}
                          </Fragment>
                        </select>
                      </th>
                      <th
                        className="text-center  align-middle hide-mobile"
                        id="th7"
                      >
                        {t('last_seen')}
                      </th>
                      <th
                        className="text-center align-middle hide-mobile"
                        id="th7"
                      >
                        {t('modify')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* verifie que la longueur de la liste est supérieure a 0 */}
                    {filteredUsers.length > 0 ? (
                      //Affichage des utilisateurs repondants aux criteres des select.
                      filteredUsers.map((useur) => (
                        <UserItem key={useur._id} user={useur} />
                      ))
                    ) : (
                      // Si la liste est vide, afficher un message
                      <Fragment>
                        <tr>
                          <td className="text-center faq-title" colSpan="7">
                            {t('no_users_found')}
                          </td>
                        </tr>
                      </Fragment>
                    )}
                  </tbody>
                </table>
              </Fragment>
            ) : (
              <h4 className="large text-error">{t('unauthorized_access')}</h4>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

UserList.propTypes = {
  getUsers: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth
});

export default connect(mapStateToProps, { getUsers })(UserList);
