import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaQuestionCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { IoMdArrowBack } from 'react-icons/io';
import { MdInfoOutline } from 'react-icons/md';
import Accordion from 'react-bootstrap/Accordion';

const CGUacg = ({ auth }) => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <h1 className="large text-ascorel">{t('cgu_title')}</h1>
      <div className="parent-flex">
        <p className="lead">
          <MdInfoOutline
            size={40}
            color="grey"
            style={{
              marginRight: '1rem'
            }}
          />
          {t('cgu_lead')}
        </p>
        {auth && auth.user ? (
          <div className="child-left-auto">
            <Link to={`/user/${auth.user._id}`}>
              <button
                className="btn btn-dark btn-lg"
                style={{
                  marginRight: '0rem',
                  marginBottom: '0.5rem'
                }}
              >
                <IoMdArrowBack
                  color="white"
                  style={{
                    marginRight: '0.2rem',
                    marginBottom: '0.2rem'
                  }}
                />
                {t('return_my_account')}
              </button>
            </Link>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="cgu-title">{t('data_prot_title')}</div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="cgu-subtitle">{t('data_prot_subtitle1')}</p>
            <p className="cgu-txt">{t('data_prot_text_1')}</p>
            <p className="cgu-subtitle">{t('data_prot_subtitle2')}</p>
            <p className="cgu-txt">{t('data_prot_text_2')}</p>
            <p className="cgu-subtitle">{t('data_prot_subtitle3')}</p>
            <p className="cgu-txt">{t('data_prot_text_3')}</p>
            <p className="cgu-subtitle">{t('data_prot_subtitle4')}</p>
            <p className="cgu-txt">{t('data_prot_text_4')}</p>
            <p className="cgu-subtitle">{t('data_prot_subtitle5')}</p>
            <p className="cgu-txt">{t('data_prot_text_5')}</p>
            <p className="cgu-subtitle">{t('data_prot_subtitle6')}</p>
            <p className="cgu-txt">{t('data_prot_text_6')}</p>
            <p className="cgu-subtitle">{t('data_prot_subtitle7')}</p>
            <p className="cgu-txt">{t('data_prot_text_7')}</p>
            <p className="cgu-subtitle">{t('data_prot_subtitle8')}</p>
            <p className="cgu-txt">{t('data_prot_text_8')}</p>
            <p className="cgu-subtitle">{t('data_prot_subtitle9')}</p>
            <p className="cgu-txt">{t('data_prot_text_9')}</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="cgu-title">{t('mentions_legales_title')}</div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="cgu-subtitle">{t('mentions_legales_subtitle1')}</p>
            <p className="cgu-txt">{t('mentions_legales_text_1_1')}</p>
            <p className="cgu-txt-bold">{t('mentions_legales_text_1_2')}</p>
            <p className="cgu-txt">{t('mentions_legales_text_1_3')}</p>
            <p className="cgu-txt-bold">{t('mentions_legales_text_1_4')}</p>
            <p className="cgu-subtitle">{t('mentions_legales_subtitle2')}</p>
            <p className="cgu-txt">{t('mentions_legales_text_2')}</p>
            <p className="cgu-subtitle">{t('mentions_legales_subtitle3')}</p>
            <p className="cgu-txt">{t('mentions_legales_text_3')}</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="cgu-title">
            <div className="cgu-title">{t('pol_confidentialite_title')}</div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="cgu-subtitle">{t('pol_confidentialite_subtitle1')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_1_1')}</p>
            <p className="cgu-txt-bold">{t('pol_confidentialite_text_1_XX')}</p>
            <p className="cgu-txt-bold">{t('pol_confidentialite_text_1_2')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_1_3')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_1_4')}</p>
            <p className="cgu-txt-bold">{t('pol_confidentialite_text_1_5')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_1_6')}</p>
            <p className="cgu-txt-bold">{t('pol_confidentialite_text_1_7')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_1_8')}</p>
            <p className="cgu-txt-bold">{t('pol_confidentialite_text_1_9')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_1_10')}</p>
            <p className="cgu-subtitle">{t('pol_confidentialite_subtitle2')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_2')}</p>
            <p className="cgu-subtitle">{t('pol_confidentialite_subtitle3')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_3')}</p>
            <p className="cgu-subtitle">{t('pol_confidentialite_subtitle4')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_4')}</p>
            <p className="cgu-subtitle">{t('pol_confidentialite_subtitle5')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_5')}</p>
            <p className="cgu-subtitle">{t('pol_confidentialite_subtitle6')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_6')}</p>
            <p className="cgu-subtitle">{t('pol_confidentialite_subtitle7')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_7')}</p>
            <p className="cgu-subtitle">{t('pol_confidentialite_subtitle8')}</p>
            <p className="cgu-txt">{t('pol_confidentialite_text_8_1')}</p>
            <p className="cgu-txt-bold">{t('pol_confidentialite_text_8_2')}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </section>
  );
};

CGUacg.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(CGUacg);
