import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getUserById, deleteUser, createUser } from '../../actions/user';
import Moment from 'react-moment';
import moment from 'moment';
import { MdDelete } from 'react-icons/md';
import { GiCoffin } from 'react-icons/gi';
import { GiRaiseZombie } from 'react-icons/gi';
import { AiOutlineEdit } from 'react-icons/ai';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useTranslation, Trans } from 'react-i18next';

const UserDetail = ({
  auth_id,
  getUserById,
  deleteUser,
  createUser,
  user: {
    user,
    // user: { _id, name, email, identifiant, rights, entity, date, enabled },
    loading
  },
  auth
}) => {
  const { t } = useTranslation();

  const DeleteCompte = () => {
    // if (window.confirm('Etes vous sur de vouloir supprimer ce compte ?')) {
    setaction(true);
    deleteUser(auth_id ? auth_id : id);
    setTimeout(() => {
      navigate('/users');
    }, 1000);
    // }
  };

  const DisableCompte = (etat, txt) => {
    if (window.confirm(txt.props.children)) {
      setaction(true);
      createUser(auth_id ? auth_id : id, { enabled: etat });
      setTimeout(() => {
        navigate(0);
      }, 1000);
    }
  };

  const navigate = useNavigate();
  const [action, setaction] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getUserById(auth_id ? auth_id : id);
  }, [getUserById, id, auth_id]);

  return (
    <section className="container">
      <Fragment>
        {user === null || loading || action ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="row">
              {auth.user.rights === 'user' ? (
                <div className="col-auto">
                  <Link to="/dashboard" className="btn btn-dark">
                    <AiOutlineArrowLeft /> {t('back_dasboard')}
                  </Link>
                </div>
              ) : (
                <div className="col-auto">
                  <Link
                    to="/users"
                    className="btn btn-dark"
                    style={{
                      marginBottom: '1rem'
                    }}
                  >
                    <AiOutlineArrowLeft /> {t('back_acc_list')}
                  </Link>
                </div>
              )}
              <div className="col-auto">
                <Link
                  to={`/edit-user/${auth_id ? auth_id : id}`}
                  className="btn btn-dark"
                  style={{
                    marginBottom: '1rem'
                  }}
                >
                  {t('modify_account')}
                  <AiOutlineEdit />
                </Link>
              </div>
              {auth.isAuthenticated &&
                auth.loading === false &&
                (auth.user.rights === 'admin' ||
                  auth.user.rights === 'admin_entity') && (
                  <Fragment>
                    {user.enabled ? (
                      <div className="col-auto">
                        <button
                          className="btn btn-warning btn-lg"
                          style={{
                            marginBottom: '1rem'
                          }}
                          onClick={() =>
                            DisableCompte(
                              false,
                              <Trans>{t('deactivate_msg')}</Trans>
                            )
                          }
                        >
                          {t('deactivate_account')} <GiCoffin />
                        </button>
                      </div>
                    ) : (
                      <div className="col-auto">
                        <button
                          className="btn btn-warning btn-lg"
                          style={{
                            marginBottom: '1rem'
                          }}
                          onClick={() =>
                            DisableCompte(
                              true,
                              <Trans>{t('activate_msg')}</Trans>
                            )
                          }
                        >
                          {t('reactivate_account')}
                          <GiRaiseZombie />
                        </button>
                      </div>
                    )}
                  </Fragment>
                )}
              {auth.isAuthenticated &&
                auth.loading === false &&
                auth.user.rights === 'admin' && (
                  <div className="col-auto">
                    <button
                      className="btn btn-danger btn-lg"
                      style={{
                        marginBottom: '1rem'
                      }}
                      onClick={() => DeleteCompte()}
                    >
                      {t('delete_account')} <delete_account />
                    </button>
                  </div>
                )}
            </div>
            {user.enabled ? (
              <div></div>
            ) : (
              <h4 className="bg-warning my-2 p-2 text-center">
                {t('account_deactivated')}
              </h4>
            )}
            <div className="profile-grid my-1">
              <div
                className={
                  user.enabled
                    ? 'profile-top bg-primary p-2'
                    : 'profile-top-disabled bg-primary p-2'
                }
              >
                {/* <img className="round-img my-4" src={avatar} alt="" /> */}
                <p className="lead">
                  {user.identifiant && (
                    <span>
                      {' '}
                      {t('email')} : {user.identifiant}
                    </span>
                  )}
                </p>{' '}
                {/* <p className="lead">
                    {user.email && (
                      <span>
                        {' '}
                        {t('email')} : {user.email}
                      </span>
                    )}
                  </p> */}
                <p className="lead">
                  {user.phone && (
                    <span>
                      {' '}
                      {t('phone')} : {user.phone}
                    </span>
                  )}
                </p>
                <p className="lead">
                  {user.name && (
                    <span>
                      {' '}
                      {t('descri')} : {user.name}
                    </span>
                  )}
                </p>
                <p className="lead">
                  {user.entity && (
                    <span>
                      {t('entity')} : {user.entity}
                    </span>
                  )}
                </p>
                <p className="lead">
                  {user.rights && (
                    <span>
                      {t('rights')} : {user.rights}
                    </span>
                  )}
                </p>
                <p className="lead">
                  {user.lastSeen ? (
                    <span>
                      {t('last_seen')} :
                      <Moment format=" DD/MM/YYYY hh:mm:ss">
                        {moment(user.lastSeen).local()}
                      </Moment>
                    </span>
                  ) : (
                    <span>{t('last_seen')} : ---</span>
                  )}
                </p>
                <p className="lead">
                  {user.date && (
                    <span>
                      {t('modify')} :
                      <Moment format=" DD/MM/YYYY hh:mm:ss">
                        {moment(user.date).local()}
                      </Moment>
                    </span>
                  )}
                </p>
              </div>
            </div>
            {auth.user._id === id ? (
              <label
                className="col-auto"
                style={{
                  fontSize: '1rem'
                }}
              >
                <Trans i18nKey="consultCGU">
                  x<Link to="/cgu">y</Link>.
                </Trans>
              </label>
            ) : (
              <Fragment></Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

UserDetail.propTypes = {
  getUserById: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getUserById,
  deleteUser,
  createUser
})(UserDetail);
