import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import EntityItem from './EntityItem';
import { getEntities } from '../../actions/entity';
import { FaRegBuilding } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { useTranslation, Trans } from 'react-i18next';

const EntityList = ({ auth, getEntities, entity: { entities, loading } }) => {
  useEffect(() => {
    getEntities();
  }, [getEntities]);
  const { t } = useTranslation();

  return (
    <section className="container">
      <Fragment>
        {loading || auth === null || entities.length === 0 ? (
          <Spinner />
        ) : (
          <Fragment>
            {auth && auth.user && auth.user.rights === 'admin' ? (
              <Fragment>
                <Trans>
                  <h1 className="large text-ascorel">{t('entities')}</h1>
                </Trans>
                <div className="parent-flex">
                  <Trans>
                    <p className="lead">
                      <FaRegBuilding size={40} color="grey" />
                      {t('entities_mngt')}
                    </p>
                  </Trans>
                  {auth && auth.user && auth.user.rights === 'admin' ? (
                    <div className="child-left-auto">
                      <Link to="/create-entity">
                        <button
                          className="btn btn-ascorel btn-lg"
                          style={{
                            marginRight: '0rem',
                            marginBottom: '0.5rem'
                          }}
                        >
                          <MdAdd
                            color="white"
                            style={{
                              marginRight: '0.2rem',
                              marginBottom: '0.2rem'
                            }}
                          />{' '}
                          <Trans>{t('entity_create')}</Trans>
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <table
                  className="table text-nowrap table-striped table-light table-header-group table-container"
                  border="0"
                >
                  <thead>
                    <tr className="sticky">
                      <Trans>
                        <th className="text-center">{t('name')}</th>
                        <th className="text-center hide-mobile">
                          {t('lvl_max_admin')}
                        </th>
                        <th className="text-center hide-mobile">
                          {t('lvl_max_user')}
                        </th>
                      </Trans>
                    </tr>
                  </thead>
                  <tbody>
                    {entities.length > 0 ? (
                      entities.map((ent) => (
                        <EntityItem key={ent._id} entity={ent} />
                      ))
                    ) : (
                      <Fragment>
                        <Trans>{t('no_entity_found')}</Trans>
                      </Fragment>
                    )}
                  </tbody>
                </table>{' '}
              </Fragment>
            ) : (
              <Trans>
                <h4 className="large text-error">{t('unauthorized_access')}</h4>
              </Trans>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

EntityList.propTypes = {
  getEntities: PropTypes.func.isRequired,
  entity: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  entity: state.entity,
  auth: state.auth
});

export default connect(mapStateToProps, { getEntities })(EntityList);
