export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_EVENT = 'GET_EVENT';
export const EVENT_ERROR = 'EVENT_ERROR';
export const CLEAR_EVENT = 'CLEAR_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const USER_ERROR = 'USER_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const USER_DELETED = 'USER_DELETED';
export const GET_ENTITY = 'GET_ENTITY';
export const GET_ENTITIES = 'GET_ENTITIES';
export const ENTITY_ERROR = 'ENTITY_ERROR';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const CLEAR_ENTITY = 'CLEAR_ENTITY';
export const ENTITY_DELETED = 'ENTITY_DELETED';
export const GET_CODE = 'GET_CODE';
export const GET_CODES = 'GET_CODES';
export const CODE_ERROR = 'CODE_ERROR';
export const UPDATE_CODE = 'UPDATE_CODE';
export const CLEAR_CODE = 'CLEAR_CODE';
export const CODE_DELETED = 'CODE_DELETED';
