import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { GrCodepen } from 'react-icons/gr';
import { FaUser } from 'react-icons/fa';
import Spinner from '../layout/Spinner';
import { FaRegBuilding } from 'react-icons/fa';
import logo from '../../img/ascorel.png';
import { useTranslation } from 'react-i18next';

const Dashboard = ({ auth, auth: { user, loading } }) => {
  const { t } = useTranslation();

  return (
    <section className="container">
      <Fragment>
        {loading || auth === null || user === null ? (
          <Spinner />
        ) : (
          <Fragment>
            <h4 className="p-3 mb-2 bg-ascorel text-white text-center">
              {t('dashboard')}
            </h4>
            {/* <p className="lead" style={{ marginTop: 50 }}>
          <i className="fas fa-user" /> Bienvenue
        </p> */}
            <div className="parent-flex">
              <div>
                <img src={logo} alt="logo" height="100" />
              </div>
              {/* <div className="child-left-auto">
                <Link to={`/user/${user._id}`}>
                  <button
                    className="btn btn-ascorel"
                    style={{ marginRight: '0rem' }}
                  >
                    Mon compte <FaUser />
                  </button>
                </Link>
              </div> */}
            </div>
            <CardGroup style={{ marginTop: 50 }}>
              <Card className="m-3 card-style-code">
                {/* <a href="/user" className="stretched-link"> */}
                <Link to={`/codes`} className="stretched-link">
                  <GrCodepen
                    size={96}
                    className="icon-center my-3"
                    color="grey"
                  />
                </Link>
                {/* </a> */}
                <Card.Body>
                  <Card.Title className="card-header-color-title">
                    {t('codes')}
                  </Card.Title>
                  {auth &&
                  auth.user &&
                  (auth.user.rights === 'admin' ||
                    auth.user.rights === 'admin_entity') ? (
                    <Card.Text className="card-header-color">
                      {t('code_lead')}
                    </Card.Text>
                  ) : (
                    <Card.Text className="card-header-color">
                      {t('code_lead_user')}
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
              {auth &&
              auth.user &&
              (auth.user.rights === 'admin' ||
                auth.user.rights === 'admin_entity') ? (
                // <Fragment>
                <Card className="m-3 card-style-users">
                  <Link to={`/users`} className="stretched-link">
                    <FaUser
                      size={96}
                      className="icon-center my-3"
                      color="grey"
                    />
                  </Link>
                  <Card.Body>
                    <Card.Title className="card-header-color-title">
                      {t('users')}
                    </Card.Title>
                    <Card.Text className="card-header-color">
                      {t('users_lead')}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : (
                <Fragment></Fragment>
              )}
              {auth && auth.user && auth.user.rights === 'admin' ? (
                // <Fragment>
                <Card className="m-3 card-style-entity">
                  <Link to={`/entities`} className="stretched-link">
                    <FaRegBuilding
                      size={96}
                      className="icon-center my-3"
                      color="grey"
                    />
                  </Link>
                  <Card.Body>
                    <Card.Title className="card-header-color-title">
                      {t('entities')}
                    </Card.Title>
                    <Card.Text className="card-header-color">
                      {t('entities_lead')}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ) : (
                // </Fragment>
                <Fragment></Fragment>
              )}
            </CardGroup>
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  logout
})(Dashboard);
