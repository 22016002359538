import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createCode } from '../../actions/code';
import { getUsers } from '../../actions/user';
import Spinner from '../layout/Spinner';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const initialState = {
  device: 'TT3 / MC603',
  targetUser: '',
  duration: '',
  selectedDate: '',
  description: '',
  level: 8,
  sendSMS: true,
  sendEmail: true
};

const CodeCreate = ({
  auth,
  createCode,
  getUsers,
  history,
  user: { users, loading },
  code: { code }
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (auth.user.rights !== 'user') {
      getUsers();
    }
  }, [getUsers, auth.user.rights]);

  const [formData, setFormData] = useState(initialState);
  const navigate = useNavigate();
  const [action, setaction] = useState(false);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let clone = {
      ...formData,
      selectedDate: moment(formData.selectedDate).format('YYYY-MM-DD HH:mm:ss'),
      lg: i18n.language
    };
    if (auth.user.rights === 'user') {
      clone = {
        ...clone,
        targetUser: auth.user._id,
        duration: 'day'
      };
    }
    setaction(true);
    createCode(clone);
    setTimeout(() => {
      navigate('/codes');
    }, 1000);
  };

  //Creation d'une liste d'identifiants pour le dynamicSelect
  function creationDynamicSelectSequence(listusers) {
    let items = [];
    items.push(
      <option selected disabled hidden value={0}>
        {t('utilisateur_code')}
      </option>
    );
    for (let i = 0; i < listusers.length; i++) {
      if (listusers[i].enabled) {
        items.push(
          <option key={i} value={listusers[i]._id}>
            {listusers[i].identifiant}
            {' ('}
            {listusers[i].name}
            {')'}
          </option>
        );
      }
    }
    return items;
  }

  return (
    <section className="container">
      {loading ||
      users.length === 0 ||
      auth.user.rights === 'user' ||
      auth === null ||
      action ? (
        <Spinner />
      ) : (
        <Fragment>
          <Fragment>
            {auth.user.rights !== 'user' ? (
              <div>
                <h1 className="large text-ascorel">{t('creer_code_title')}</h1>
                <p className="lead">
                  <i className="fas fa-user" />
                  {t('creer_code_lead')}
                </p>
              </div>
            ) : (
              <div>
                <h1 className="large text-ascorel">{t('quick_code_title')}</h1>
                <p className="lead">
                  <i className="fas fa-user" /> {t('quick_code_lead')}
                </p>
              </div>
            )}

            <small>{t('champs_requis')}</small>
            <form
              className="form"
              style={{
                alignItems: 'center'
              }}
              onSubmit={onSubmit}
            >
              {auth.user.rights !== 'user' && (
                <Fragment>
                  <div className="form-group">
                    <Form.Select
                      class="form-control"
                      name="targetUser"
                      onChange={(e) => onChange(e)}
                    >
                      {creationDynamicSelectSequence(users)}
                    </Form.Select>
                    <small className="form-text">{t('target_user_expl')}</small>
                  </div>
                  <div className="form-group">
                    <Form.Select
                      className="form-control"
                      name="device"
                      onChange={(e) => onChange(e)}
                    >
                      <option value="0">{t('product_expl')}</option>

                      <option selected value="TT3 / MC603">
                        TT3 / MC603
                      </option>
                    </Form.Select>
                    <small className="form-text">{t('product_expl')}</small>
                  </div>
                </Fragment>
              )}

              <div className="form-group">
                <Form.Control
                  type="date"
                  max={
                    auth.user.rights === 'user'
                      ? moment.utc().endOf('week').format('YYYY-MM-DD')
                      : null
                  }
                  min={
                    auth.user.rights === 'user'
                      ? moment.utc().startOf('week').format('YYYY-MM-DD')
                      : null
                  }
                  name="selectedDate"
                  onChange={(e) => onChange(e)}
                  placeholder="* Date début"
                />
                <small className="form-text">{t('start_date_expl')}</small>
              </div>

              {auth.user.rights !== 'user' && (
                <div className="form-group">
                  <Form.Select
                    className="form-control"
                    name="duration"
                    onChange={(e) => onChange(e)}
                  >
                    <option value="0">{t('select_duration')}</option>
                    <option value="day">{t('day')}</option>
                    <option value="week">{t('week')}</option>
                    <option value="month">{t('month')}</option>
                    <option value="trimester">{t('trimester')}</option>
                    <option value="semester">{t('semester')}</option>
                    <option value="year">{t('year')}</option>
                  </Form.Select>
                  <small className="form-text">{t('duration_expl')}</small>
                </div>
              )}

              {auth.user.rights === 'admin' && (
                <div className="form-group">
                  <Form.Select
                    className="form-control"
                    name="level"
                    onChange={(e) => onChange(e)}
                    // disabled={formData.targetUser === ''}
                  >
                    <option selected disabled hidden value={0}>
                      {t('code_level')}
                    </option>
                    <option value="1" key="1">
                      {t('level_1')}
                    </option>
                    <option value="2" key="2">
                      {t('level_2')}
                    </option>
                    <option value="3" key="3">
                      {t('level_3')}
                    </option>
                    <option value="4" key="4">
                      {t('level_4')}
                    </option>
                    <option value="5" key="5">
                      {t('level_5')}
                    </option>
                    <option value="6" key="6">
                      {t('level_6')}
                    </option>
                    <option value="7" key="7">
                      {t('level_7')}
                    </option>
                    <option value="8" key="8">
                      {t('level_8')}
                    </option>
                    <option value="9" key="9">
                      {t('level_9')}
                    </option>
                    <option value="10" key="10">
                      {t('level_10')}
                    </option>
                    <option value="11" key="11">
                      {t('level_11')}
                    </option>
                    <option value="12" key="12">
                      {t('level_12')}
                    </option>
                    <option value="13" key="13">
                      {t('level_13')}
                    </option>
                    <option value="14" key="14">
                      {t('level_14')}
                    </option>
                    <option value="15" key="15">
                      {t('level_15')}
                    </option>
                    <option value="16" key="16">
                      {t('level_16')}
                    </option>
                  </Form.Select>
                  <small className="form-text">{t('niveau_small')}</small>
                </div>
              )}
              <div className="form-group">
                <Form.Control
                  type="text"
                  name="description"
                  onChange={(e) => onChange(e)}
                  placeholder="Description"
                />
                <small className="form-text">{t('descri_expl')}</small>
              </div>

              <div className="checkbox" style={{ marginBottom: '1rem' }}>
                <Form.Check
                  className="col-auto"
                  defaultChecked={true}
                  name="sendSMS"
                  onClick={(e) =>
                    onChange({
                      target: {
                        name: 'sendSMS',
                        value: e.target.checked
                      }
                    })
                  }
                />
                <label
                  className="col-auto"
                  style={{ marginLeft: '0.5rem', marginTop: '0.2rem' }}
                >
                  {t('sms_send')}
                </label>
              </div>
              <div className="checkbox" style={{ marginBottom: '1rem' }}>
                <Form.Check
                  className="col-auto"
                  defaultChecked={true}
                  name="sendEmail"
                  onClick={(e) =>
                    onChange({
                      target: {
                        name: 'sendEmail',
                        value: e.target.checked
                      }
                    })
                  }
                />
                <label
                  className="col-auto"
                  style={{ marginLeft: '0.5rem', marginTop: '0.2rem' }}
                >
                  {t('email_send')}
                </label>
              </div>

              <input
                type="submit"
                className="btn btn-primary my-1"
                value={t('create')}
              />

              <button
                type="button"
                className="btn btn-light my-1"
                onClick={() => navigate(-1)}
              >
                {t('cancel')}
              </button>
            </form>
          </Fragment>
        </Fragment>
      )}
    </section>
  );
};

CodeCreate.propTypes = {
  createCode: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  code: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  code: state.code,
  auth: state.auth
});

export default connect(mapStateToProps, { createCode, getUsers })(CodeCreate);
