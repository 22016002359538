import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import CodeItem from './CodeItem';
import { getCurrentUserCreatedCodes } from '../../actions/code';
import { RiEmotionSadLine } from 'react-icons/ri';
import { GrCodepen } from 'react-icons/gr';
import { IoMdArrowBack } from 'react-icons/io';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation, Trans } from 'react-i18next';

const CodeCreatedList = ({
  auth,
  getCurrentUserCreatedCodes,
  code: { codes, loading }
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getCurrentUserCreatedCodes();
  }, [getCurrentUserCreatedCodes]);

  return (
    <section className="container">
      <Fragment>
        {loading || auth === null ? (
          <Spinner />
        ) : (
          <Fragment>
            {auth &&
            auth.user &&
            (auth.user.rights === 'admin' ||
              auth.user.rights === 'admin_entity') ? (
              <Fragment>
                <h1 className="large text-ascorel">
                  {t('created_codes_title')}
                </h1>
                <div className="parent-flex">
                  <p className="lead">
                    <GrCodepen size={40} color="grey" />{' '}
                    {t('created_codes_lead')}
                  </p>
                  {auth &&
                  auth.user &&
                  (auth.user.rights === 'admin' ||
                    auth.user.rights === 'admin_entity') ? (
                    <div className="child-left-auto">
                      <Link to="/codes">
                        <button
                          className="btn btn-dark btn-lg"
                          style={{
                            marginRight: '0rem',
                            marginBottom: '0.5rem'
                          }}
                        >
                          <IoMdArrowBack
                            color="white"
                            style={{
                              marginRight: '0.2rem',
                              marginBottom: '0.2rem'
                            }}
                          />{' '}
                          {t('return_assigned_code')}
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('valid_codes')}</Accordion.Header>
                    <Accordion.Body>
                      {codes.length > 0 ? (
                        <table
                          className="table text-nowrap table-striped table-light table-header-group table-container"
                          border="0"
                        >
                          <thead>
                            <tr className="sticky">
                              <th className="text-center " id="th1"></th>
                              <th className="text-center hide-mobile" id="th2">
                                {t('code_cree_pour')}
                              </th>
                              <th className="text-center hide-mobile" id="th3">
                                {t('code_produit')}
                              </th>
                              <th className="text-center" id="th4">
                                {t('code_date_debut')}
                              </th>
                              <th className="text-center hide-mobile" id="th5">
                                {t('code_date_fin')}
                              </th>
                              <th className="text-center hide-mobile" id="th6">
                                {t('code_date_create')}
                              </th>
                              <th className="text-center" id="th7">
                                {t('code_duration')}
                              </th>
                              <th className="text-center hide-mobile" id="th8">
                                {t('code_state')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {codes.length > 0 ? (
                              codes.map((cd) => (
                                <CodeItem
                                  key={cd._id}
                                  code={cd}
                                  valid={true}
                                  userOrCreator="user"
                                />
                              ))
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <Fragment>{t('code_not_found')}</Fragment>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('invalid_codes')}</Accordion.Header>
                    <Accordion.Body>
                      {codes.length > 0 ? (
                        <table
                          className="table text-nowrap table-striped table-light table-header-group table-container"
                          border="0"
                        >
                          <thead>
                            <tr className="sticky">
                              <th className="text-center " id="th1"></th>
                              <th className="text-center hide-mobile" id="th3">
                                {t('code_cree_pour')}
                              </th>
                              <th className="text-center hide-mobile" id="th3">
                                {t('code_produit')}
                              </th>
                              <th className="text-center" id="th4">
                                {t('code_date_debut')}
                              </th>
                              <th className="text-center hide-mobile" id="th5">
                                {t('code_date_fin')}
                              </th>
                              <th className="text-center hide-mobile" id="th6">
                                {t('code_date_create')}
                              </th>
                              <th className="text-center" id="th7">
                                {t('code_duration')}
                              </th>
                              <th className="text-center hide-mobile" id="th7">
                                {t('code_state')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {codes.length > 0 ? (
                              codes.map((cd) => (
                                <CodeItem
                                  key={cd._id}
                                  code={cd}
                                  valid={false}
                                  userOrCreator="user"
                                />
                              ))
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <Fragment>{t('code_not_found')}</Fragment>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Fragment>
            ) : (
              <h4 className="large text-error">{t('unauthorized_access')}</h4>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

CodeCreatedList.propTypes = {
  getCurrentUserCreatedCodes: PropTypes.func.isRequired,
  code: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  code: state.code,
  auth: state.auth
});

export default connect(mapStateToProps, { getCurrentUserCreatedCodes })(
  CodeCreatedList
);
