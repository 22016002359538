import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const EntityItem = ({
  entity: { _id, name, maxLvlAdminEntity, maxLvlUser }
}) => {
  return (
    <Fragment>
      <tr>
        <td class="text-center align-middle ">
          {' '}
          <Link to={`/entity/${_id}`}>
            <span class="text-center align-middle col-8">{name}</span>
          </Link>
        </td>
        <td class="text-center align-middle col-4 hide-mobile">
          {maxLvlAdminEntity}
        </td>
        <td class="text-center align-middle col-4 hide-mobile">{maxLvlUser}</td>
      </tr>
    </Fragment>
  );
};

EntityItem.propTypes = {
  entity: PropTypes.object.isRequired
};

export default EntityItem;
