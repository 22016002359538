import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import CodeItem from './CodeItem';
import { getCurrentUserCodes } from '../../actions/code';
import { GrCodepen } from 'react-icons/gr';
import Accordion from 'react-bootstrap/Accordion';
import { MdHistory } from 'react-icons/md';
import { MdAdd } from 'react-icons/md';
import { useTranslation, Trans } from 'react-i18next';

const CodeList = ({ auth, getCurrentUserCodes, code: { codes, loading } }) => {
  const { t } = useTranslation();

  useEffect(() => {
    getCurrentUserCodes();
  }, [getCurrentUserCodes]);

  return (
    <section className="container">
      <Fragment>
        {loading || auth === null ? (
          <Spinner />
        ) : (
          <Fragment>
            {auth &&
            auth.user &&
            (auth.user.rights === 'admin' ||
              auth.user.rights === 'admin_entity' ||
              auth.user.rights === 'user') ? (
              <Fragment>
                <Trans>
                  <h1 className="large text-ascorel">
                    {t('assigned_codes_title')}
                  </h1>
                </Trans>
                <div className="parent-flex">
                  <Trans>
                    <p className="lead">
                      {' '}
                      <GrCodepen
                        size={40}
                        color="grey"
                        style={{
                          marginRight: '1rem'
                        }}
                      />
                      {t('assigned_codes_lead')}
                    </p>
                  </Trans>{' '}
                  <div className="child-left-auto">
                    {auth &&
                    auth.user &&
                    (auth.user.rights === 'admin' ||
                      auth.user.rights === 'admin_entity') ? (
                      <Trans>
                        <Link to="/codescreated">
                          <button
                            className="btn btn-ascorel btn-lg"
                            style={{
                              marginRight: '1rem',
                              marginBottom: '0.5rem'
                            }}
                          >
                            <MdHistory
                              color="white"
                              style={{
                                marginRight: '0.2rem',
                                marginBottom: '0.2rem'
                              }}
                            />{' '}
                            {t('code_history')}
                          </button>
                        </Link>
                      </Trans>
                    ) : (
                      <div></div>
                    )}
                    {auth.user.rights !== 'user' ? (
                      <Link to="/create-code">
                        <button
                          className="btn btn-ascorel btn-lg"
                          style={{
                            marginRight: '0rem',
                            marginBottom: '0.5rem'
                          }}
                        >
                          <MdAdd
                            color="white"
                            style={{
                              marginRight: '0.2rem',
                              marginBottom: '0.2rem'
                            }}
                          />{' '}
                          <Trans>
                            {auth.user.rights === 'user' ? (
                              <Fragment> {t('code_quick')}</Fragment>
                            ) : (
                              <Fragment>{t('code_create')}</Fragment>
                            )}
                          </Trans>
                        </button>
                      </Link>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </div>
                </div>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('valid_codes')}</Accordion.Header>
                    <Accordion.Body>
                      {codes.length > 0 ? (
                        <table
                          className="table text-nowrap table-striped table-light table-header-group table-container"
                          border="0"
                        >
                          <thead>
                            <tr className="sticky">
                              <th className="text-center " id="th1"></th>
                              <th className="text-center hide-mobile" id="th2">
                                {t('code_cree_par')}
                              </th>
                              <th className="text-center hide-mobile" id="th3">
                                {t('code_produit')}
                              </th>
                              <th className="text-center" id="th4">
                                {t('code_date_debut')}
                              </th>
                              <th className="text-center hide-mobile" id="th5">
                                {t('code_date_fin')}
                              </th>
                              <th className="text-center hide-mobile" id="th6">
                                {t('code_date_create')}
                              </th>
                              <th className="text-center" id="th7">
                                {t('code_duration')}
                              </th>
                              <th className="text-center hide-mobile" id="th8">
                                {t('code_state')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {codes.length > 0 ? (
                              codes.map((cd) => (
                                <CodeItem
                                  key={cd._id}
                                  code={cd}
                                  valid={true}
                                  userOrCreator="creator"
                                />
                              ))
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <Fragment>{t('code_not_found_valid')}</Fragment>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('invalid_codes')}</Accordion.Header>
                    <Accordion.Body>
                      {codes.length > 0 ? (
                        <table
                          className="table text-nowrap table-striped table-light table-header-group table-container"
                          border="0"
                        >
                          <thead>
                            <tr className="sticky">
                              <th className="text-center " id="th1"></th>
                              <th className="text-center hide-mobile" id="th3">
                                {t('code_cree_par')}
                              </th>
                              <th className="text-center hide-mobile" id="th3">
                                {t('code_produit')}
                              </th>
                              <th className="text-center" id="th4">
                                {t('code_date_debut')}
                              </th>
                              <th className="text-center hide-mobile" id="th5">
                                {t('code_date_fin')}
                              </th>
                              <th className="text-center hide-mobile" id="th6">
                                {t('code_date_create')}
                              </th>
                              <th className="text-center" id="th7">
                                {t('code_duration')}
                              </th>
                              <th className="text-center hide-mobile" id="th7">
                                {t('code_state')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {codes.length > 0 ? (
                              codes.map((cd) => (
                                <CodeItem
                                  key={cd._id}
                                  code={cd}
                                  valid={false}
                                  userOrCreator="creator"
                                />
                              ))
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <Fragment>{t('code_not_found')}</Fragment>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      {t('old_versions_codes')}
                    </Accordion.Header>
                    <Accordion.Body>
                      {codes.length > 0 ? (
                        <Fragment>
                          <table
                            className="table text-nowrap table-striped table-light table-header-group table-container"
                            border="0"
                            style={{ margin: 0 }}
                          >
                            <thead>
                              <tr className="sticky">
                                <th className="text-center ">
                                  {t('title_old_version_1')}
                                </th>
                              </tr>
                            </thead>
                          </table>
                          <table
                            className="table text-nowrap table-striped table-light table-header-group table-container"
                            border="0"
                          >
                            <thead>
                              <tr className="sticky">
                                <td className="text-center ">{t('id')}</td>
                                <td className="text-center ">{t('pw_ph')}</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  className="text-center align-middle "
                                  style={{ fontWeight: 'bold' }}
                                >
                                  5099
                                </td>
                                <td
                                  className="text-center align-middle "
                                  style={{ fontWeight: 'bold' }}
                                >
                                  4abe46bd
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            className="table text-nowrap table-striped table-light table-header-group table-container"
                            border="0"
                            style={{ margin: 0 }}
                          >
                            <thead>
                              <tr className="sticky">
                                <th className="text-center ">
                                  {t('title_old_version_2')}
                                </th>
                              </tr>
                            </thead>
                          </table>
                          <table
                            className="table text-nowrap table-striped table-light table-header-group table-container"
                            border="0"
                          >
                            <thead>
                              <tr className="sticky">
                                <td className="text-center ">{t('id')}</td>
                                <td className="text-center ">{t('pw_ph')}</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  className="text-center align-middle "
                                  style={{ fontWeight: 'bold' }}
                                >
                                  5099
                                </td>
                                <td
                                  className="text-center align-middle "
                                  style={{ fontWeight: 'bold' }}
                                >
                                  eb309d5c
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Fragment>
                      ) : (
                        <Fragment>{t('code_not_found')}</Fragment>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Fragment>
            ) : (
              <h4 className="large text-error">{t('unauthorized_access')}</h4>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

CodeList.propTypes = {
  getCurrentUserCodes: PropTypes.func.isRequired,
  code: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  code: state.code,
  auth: state.auth
});

export default connect(mapStateToProps, { getCurrentUserCodes })(CodeList);
