import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import { AiOutlineLogout } from 'react-icons/ai';
import logo from '../../img/favicon2.png';
import { GrCodepen } from 'react-icons/gr';
import { FaUser } from 'react-icons/fa';
import { FaQuestionCircle } from 'react-icons/fa';
import { FaRegBuilding } from 'react-icons/fa';
import { BsJournalText } from 'react-icons/bs';
import { MdLanguage } from 'react-icons/md';
import NavDropdown from 'react-bootstrap/NavDropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { useTranslation, Trans } from 'react-i18next';

const DeconnexionUtilisateur = (logout, setAlert) => {
  if (window.confirm('Etes vous sur de vouloir vous deconnecter ?')) {
    setAlert('Vous avez été deconnecté', 'danger');
    logout();
  }
};

const NavbarACG = ({ auth: { isAuthenticated, loading, user }, logout }) => {
  const { t, i18n } = useTranslation();

  const authLinks = (
    <Fragment>
      <ul>
        <li
          style={{
            marginTop: '0.4rem'
          }}
        >
          <Link to="/codes" className="text-navbar">
            <GrCodepen
              style={{
                marginRight: '0.2rem',
                marginBottom: '0.2rem'
              }}
            />{' '}
            Codes
          </Link>
        </li>
        {user && (user.rights === 'admin' || user.rights === 'admin_entity') ? (
          <li
            style={{
              marginTop: '0.3rem'
            }}
          >
            <Link to="/users" className="text-navbar">
              <FaUser
                style={{
                  marginRight: '0.2rem',
                  marginBottom: '0.2rem'
                }}
              />{' '}
              {t('users')}
            </Link>
          </li>
        ) : (
          <Fragment></Fragment>
        )}
        {user && user.rights === 'admin' ? (
          <li
            style={{
              marginTop: '0.4rem'
            }}
          >
            <Link to="/entities" className="text-navbar">
              <FaRegBuilding
                style={{
                  marginRight: '0.2rem',
                  marginBottom: '0.2rem'
                }}
              />{' '}
              {t('entities')}
            </Link>
          </li>
        ) : (
          <Fragment></Fragment>
        )}
        <li
          style={{
            marginTop: '0.4rem'
          }}
        >
          <Link to="/faq" className="text-navbar">
            <FaQuestionCircle
              style={{
                marginRight: '0.2rem',
                marginBottom: '0.2rem'
              }}
            />{' '}
            FAQ
          </Link>
        </li>
        {user ? (
          <li>
            <NavDropdown
              align="top"
              title={user && user.identifiant}
              className="text-navbar"
            >
              <NavDropdown.Item
                eventKey={2.1}
                as={Link}
                to={`/user/${user._id}`}
                className=" navbar-bg"
              >
                <FaUser size={20} /> <span>{t('my_account')}</span>
              </NavDropdown.Item>
              {user && user.rights === 'admin' ? (
                <NavDropdown.Item
                  eventKey={2.2}
                  as={Link}
                  to={`/eventlog`}
                  className="navbar-bg"
                >
                  <BsJournalText size={20} />

                  <span>{t('log')}</span>
                </NavDropdown.Item>
              ) : (
                <Fragment></Fragment>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item
                className="navbar-bg"
                onClick={() => DeconnexionUtilisateur(logout, setAlert)}
              >
                <AiOutlineLogout size={20} /> <span>{t('logout')}</span>
              </NavDropdown.Item>
            </NavDropdown>
          </li>
        ) : (
          <Fragment></Fragment>
        )}
      </ul>
    </Fragment>
  );

  const guestLinks = (
    <ul>
      <li
        style={{
          marginTop: '0.4rem'
        }}
      >
        <Link to="/faq" className="text-navbar">
          <FaQuestionCircle
            style={{
              marginRight: '0.2rem',
              marginBottom: '0.2rem'
            }}
          />{' '}
          FAQ
        </Link>
      </li>
      <li
        style={{
          marginTop: '0.4rem'
        }}
      >
        <Link to="/login" className="text-navbar">
          Login
        </Link>
      </li>
    </ul>
  );

  return (
    <nav className="navbar bg-ascorel">
      <ul>
        <li style={{ marginTop: 12, marginRight: 12 }}>
          <select
            name="rights"
            className="bg-ascorel"
            onChange={(e) => i18n.changeLanguage(e.target.value)}
            value={i18n.language}
          >
            <option value="fr">FR</option>
            <option value="en">EN</option>
          </select>
          {/* <NavDropdown
            align="top"
            title={titleLang}
            className="style-navbar"
            onChange={(e) => i18n.changeLanguage(e.target.value)}
          >
            <NavDropdown.Item key="fr" eventKey="fr">
              FR
            </NavDropdown.Item>
            <NavDropdown.Item key="eng" eventKey="eng">
              EN
            </NavDropdown.Item>
          </NavDropdown> */}
        </li>
        <li>
          <img src={logo} alt="logo" height="40" />
        </li>
        {/* <RiLockPasswordLine size={30} /> */}
        <li>
          <h2 className="titre-navbar">
            {/* <Link to="/">Générateur de code d'accès</Link> */}

            <Link to="/">{t('titre_app')}</Link>
          </h2>
        </li>
        <li style={{ marginTop: 20 }}>1.0.0</li>
      </ul>
      {!loading && (
        <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
      )}
    </nav>
  );
};

NavbarACG.propTypes = {
  logout: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  logout,
  setAlert
})(NavbarACG);
