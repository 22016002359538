import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_ENTITY,
  GET_ENTITIES,
  ENTITY_ERROR,
  UPDATE_ENTITY,
  CLEAR_ENTITY,
  ENTITY_DELETED
} from './types';

// Get all entities
export const getEntities = () => async (dispatch) => {
  dispatch({ type: CLEAR_ENTITY });

  try {
    const res = await axios.get('/api/entity');

    dispatch({
      type: GET_ENTITIES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ENTITY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get entity by ID
export const getEntityById = (entityId) => async (dispatch) => {
  try {
    console.log('axios');

    const res = await axios.get(`/api/entity/id/${entityId}`);

    dispatch({
      type: GET_ENTITY,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ENTITY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create or update entity
export const createEntity = (id, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post(
      id ? `/api/entity/${id}` : '/api/entity',
      formData,
      config
    );

    dispatch({
      type: UPDATE_ENTITY,
      payload: res.data
    });

    dispatch(setAlert(id ? 'entity updated' : 'entity created', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: ENTITY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete entity
export const deleteEntity = (entityId) => async (dispatch) => {
  if (
    window.confirm('Etes vous sur ? Cette opération ne peut PAS être annulée !')
  ) {
    try {
      await axios.delete(`/api/entity/${entityId}`);

      dispatch({ type: ENTITY_DELETED });

      dispatch(
        setAlert("L'entité a été effacé de manière definitive", 'danger')
      );
    } catch (err) {
      dispatch({
        type: ENTITY_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};
