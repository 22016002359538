import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams, useMatch, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUser, getUserById } from '../../actions/user';
import { getEntities } from '../../actions/entity';
import { setAlert } from '../../actions/alert';
import Spinner from '../layout/Spinner';
import { useTranslation, Trans } from 'react-i18next';
import { GrCircleInformation } from 'react-icons/gr';

const initialState = {
  name: '',
  // email: '',
  phone: '',
  identifiant: '',
  entity: '',
  rights: '',
  password: '',
  password2: ''
};

const UserEdit = ({
  setAlert,
  auth,
  user: { user, loading },
  entity: { entities },
  createUser,
  getUserById,
  getEntities,
  history
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState(initialState);
  const [action, setaction] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const creatingUser = useMatch('/create-user');

  useEffect(() => {
    if (!user && !creatingUser) {
      getUserById(id);
    }
    if (!loading) {
      if (!creatingUser) {
        const userData = { ...initialState };
        for (const key in user) {
          if (key in userData) userData[key] = user[key];
        }
        setFormData(userData);
      } else {
        if (auth.user.rights === 'admin_entity') {
          const userData = { ...initialState };
          userData.entity = auth.user.entity;
          userData.rights = 'user';
          setFormData(userData);
        }
      }
    }
  }, [loading, user, id, getUserById, creatingUser]);

  useEffect(() => {
    if (auth.user.rights === 'admin') {
      getEntities();
    }
  }, [getEntities, auth.user.rights]);

  const {
    name,
    identifiant,
    // email,
    phone,
    entity,
    rights,
    password,
    password2
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!creatingUser && password !== password2) {
      setAlert(<Trans>{t('password_match_err')}</Trans>, 'danger');
    } else if (!creatingUser && password.length > 0 && password.length < 6) {
      setAlert(<Trans>{t('password_6_error')}</Trans>, 'danger');
    } else {
      const phoneCleaned = phone.replace(/[- .]/g, '');
      const formDataCleand = { ...formData, phone: phoneCleaned };

      const res = await createUser(
        user ? user._id : null,
        formDataCleand,
        history
      );

      if (res && res.status === 200) {
        setaction(true);
        if (auth.user.rights === 'user') {
          setTimeout(() => {
            navigate(`/user/${user._id}`);
          }, 1000);
        } else {
          if (creatingUser) {
            setTimeout(() => {
              navigate('/users');
            }, 5000);
          } else {
            setTimeout(() => {
              navigate('/users');
            }, 1000);
          }
        }
      }
    }
  };

  return (
    <section className="container">
      <Fragment>
        {action || (entities.length === 0 && auth.user.rights === 'admin') ? (
          <div>
            {action && creatingUser ? (
              <div
                className="card-header-color text-center"
                style={{ marginTop: '1rem' }}
              >
                <GrCircleInformation size={40} color="grey" />
                <p className="lead" style={{ marginTop: '1rem' }}>
                  {t('activation_email_sent')}
                </p>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        ) : (
          <Fragment>
            {auth.user.rights === 'admin' ||
            auth.user.rights === 'admin_entity' ||
            auth.user.rights === 'user' ? (
              <Fragment>
                <h1 className="large text-ascorel">
                  {creatingUser ? (
                    <Trans>{t('create_account_title')}</Trans>
                  ) : (
                    <Trans>{t('mod_account_title')}</Trans>
                  )}
                </h1>
                <p className="lead">
                  <i className="fas fa-user" />
                  {creatingUser ? (
                    <Trans>{t('creer_code_lead')}</Trans>
                  ) : (
                    <Trans>{t('mod_account_lead')}</Trans>
                  )}
                </p>
                <small>{t('champs_requis')}</small>
                <form className="form" onSubmit={onSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      placeholder={t('ph_email')}
                      name="identifiant"
                      value={identifiant}
                      onChange={(e) => onChange(e)}
                      required
                    />
                    <small className="form-text">{t('must_unique')}</small>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder={t('phone_ph')}
                      name="phone"
                      value={phone}
                      onChange={(e) => onChange(e)}
                      required
                      pattern="[0-9]{12,15}"
                    />
                    <small className="form-text">{t('phone_hint')}</small>
                  </div>
                  <div className="form-group">
                    <select
                      name="rights"
                      value={rights}
                      required
                      onChange={(e) => onChange(e)}
                    >
                      <option value="">{t('rights_select')}</option>
                      {auth.user.rights === 'admin' ? (
                        <Trans>
                          <option value="admin">{t('rights_admin')}</option>
                        </Trans>
                      ) : null}
                      {auth.user.rights === 'admin' ||
                      auth.user.rights === 'admin_entity' ? (
                        <Trans>
                          <option value="admin_entity">
                            {t('rights_admin_ent')}
                          </option>
                        </Trans>
                      ) : null}
                      <option value="user">{t('rights_user')}</option>
                    </select>
                    <small className="form-text">{t('rights_hint')}</small>
                  </div>
                  <div className="form-group">
                    <select
                      name="entity"
                      value={entity}
                      onChange={(e) => onChange(e)}
                      required
                    >
                      <option value="">{t('entity_select')}</option>
                      {auth.user.rights === 'admin' ? (
                        <Fragment>
                          <option value="admin">admin</option>
                          {entities.map((ent, index) => (
                            <option key={index} value={ent.name}>
                              {ent.name}
                            </option>
                          ))}
                        </Fragment>
                      ) : (
                        <option value={auth.user.entity}>
                          {auth.user.entity}
                        </option>
                      )}
                    </select>
                    <small className="form-text">{t('entity_hint')}</small>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder={t('name_ph')}
                      name="name"
                      value={name}
                      onChange={onChange}
                    />
                    <small className="form-text">{t('name_hint')}</small>
                  </div>
                  {!creatingUser ? (
                    <div>
                      <div className="form-group">
                        <input
                          type="password"
                          placeholder={t('pass_ph')}
                          name="password"
                          value={password}
                          onChange={(e) => onChange(e)}
                        />
                        <small className="form-text">{t('pass_hint')}</small>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          placeholder={t('pass2_ph')}
                          name="password2"
                          value={password2}
                          onChange={(e) => onChange(e)}
                        />
                        <small className="form-text">{t('pass2_hint')}</small>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {creatingUser ? (
                    <input
                      type="submit"
                      className="btn btn-primary my-1"
                      value={t('create')}
                    />
                  ) : (
                    <input
                      type="submit"
                      className="btn btn-primary my-1"
                      value={t('save')}
                    />
                  )}
                  <Link className="btn btn-light my-1" to="/users">
                    {t('cancel')}
                  </Link>
                </form>
              </Fragment>
            ) : (
              <p>{t('unauthorized_access')}</p>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

UserEdit.propTypes = {
  createUser: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  getEntities: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  entity: state.entity,
  user: state.user,
  auth: state.auth
});

export default connect(mapStateToProps, {
  createUser,
  getUserById,
  getEntities,
  setAlert
})(UserEdit);
