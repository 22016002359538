import React, { Fragment, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { useTranslation, Trans } from 'react-i18next';

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    identifiant: '',
    password: ''
  });
  const { t } = useTranslation();

  const { identifiant, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(identifiant, password);
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section className="container">
      <Fragment>
        <h1 className="large text-primary">Login</h1>
        <p className="lead">
          <i className="fas fa-user" />
          <Trans>{t('login_lead')}</Trans>
        </p>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <Trans>
              <input
                type="text"
                placeholder={t('id_ph')}
                name="identifiant"
                value={identifiant}
                onChange={(e) => onChange(e)}
                required
              />
            </Trans>
          </div>
          <div className="form-group">
            <Trans>
              <input
                type="password"
                placeholder={t('pw_ph')}
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                minLength="6"
              />
            </Trans>
          </div>
          <input type="submit" className="btn btn-primary" value="Login" />
        </form>
        <Trans>
          <p className="my-1">
            {t('pw_fg')} <Link to="/verifyemail">{t('pw_fg1')}</Link>
          </p>
        </Trans>
      </Fragment>
    </section>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);
