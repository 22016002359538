import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import {
  getCodeById,
  deleteCode,
  sendCodeSMS,
  sendCodeEmail
} from '../../actions/code';
import Moment from 'react-moment';
import moment from 'moment';
import { MdDelete } from 'react-icons/md';
import { MdOutgoingMail } from 'react-icons/md';
import { MdSms } from 'react-icons/md';
import { IoMdArrowBack } from 'react-icons/io';
import { useTranslation, Trans } from 'react-i18next';

const DURATION_FRENCH_EQUIV = {
  day: 'Journée',
  week: 'Semaine',
  month: 'Mois',
  trimester: 'Trimestre',
  semester: 'Semestre',
  year: 'Année'
};

const DURATION_ENG_EQUIV = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  trimester: 'Trimester',
  semester: 'Semester',
  year: 'Year'
};

const CodeDetail = ({
  code_id,
  getCodeById,
  deleteCode,
  sendCodeSMS,
  sendCodeEmail,
  code: { code, loading },
  auth
}) => {
  const { t, i18n } = useTranslation();

  const DeleteCode = () => {
    setaction(true);
    deleteCode(code_id ? code_id : id);
    setTimeout(() => {
      navigate('/codes');
    }, 1000);
  };

  const navigate = useNavigate();
  const [action, setaction] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getCodeById(code_id ? code_id : id);
  }, [getCodeById, id, code_id]);

  return (
    <section className="container">
      <Fragment>
        {code === null || code.identifiant === null || loading || action ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="parent-flex">
              <div className="col">
                <button
                  className="btn btn-dark btn-lg"
                  onClick={() => navigate(-1)}
                >
                  <IoMdArrowBack
                    color="white"
                    style={{
                      marginRight: '0.2rem',
                      marginBottom: '0.2rem'
                    }}
                  />{' '}
                  <Trans>{t('back_to_code_list')}</Trans>
                </button>
              </div>
              <div className="">
                <button
                  className="btn btn-ascorel btn-lg"
                  onClick={() => sendCodeEmail(code._id, i18n.language)}
                >
                  <MdOutgoingMail
                    color="white"
                    style={{
                      marginRight: '0.2rem',
                      marginBottom: '0.2rem'
                    }}
                  />{' '}
                  {t('resend_email')}
                </button>
              </div>
              <div className="">
                <button
                  className="btn btn-ascorel btn-lg"
                  onClick={() => sendCodeSMS(code._id, i18n.language)}
                >
                  <MdSms
                    color="white"
                    style={{
                      marginRight: '0.2rem',
                      marginBottom: '0.2rem'
                    }}
                  />{' '}
                  {t('resend_sms')}
                </button>
              </div>
              {auth.isAuthenticated &&
                auth.loading === false &&
                auth.user.rights === 'admin' && (
                  <div className="child-left-auto">
                    <button
                      className="btn btn-danger btn-lg"
                      onClick={() => DeleteCode()}
                    >
                      <MdDelete
                        style={{
                          marginRight: '0.2rem',
                          marginBottom: '0.2rem'
                        }}
                      />{' '}
                      <Trans>{t('deleted_code')}</Trans>
                    </button>
                  </div>
                )}
            </div>

            {code && code.code ? (
              <div>
                <h4
                  className="p-3 mb-1 bg-ascorel text-white text-center"
                  style={{ marginTop: 10 }}
                >
                  {code.code && <span> Code : {code.code}</span>}
                </h4>
                <div className="parent-flex">
                  <p className="text-detail child-left-auto">
                    <Trans>{t('msg_version_min')}</Trans>
                  </p>
                </div>
              </div>
            ) : (
              <h4
                className="p-3 mb-1 bg-ascorel text-white text-center"
                style={{ marginTop: 10 }}
              >
                <span> Code : *****</span>
              </h4>
            )}

            <h4
              className="p-3 mb-1 bg-ascorel text-white text-center"
              style={{ marginTop: 0 }}
            >
              {code.IdOnDevice && <span> ID : {code.IdOnDevice}</span>}
            </h4>
            <div className="profile-grid my-1">
              <div className="profile-top bg-secondary p-2">
                <p className="lead">
                  {code.targetUser.identifiant && (
                    <Trans>
                      <span>
                        {t('assigned_to')}
                        {' : '}
                        {code.targetUser.identifiant}
                      </span>
                    </Trans>
                  )}
                </p>
                <p className="lead">
                  {code.creatorUser.identifiant && (
                    <Trans>
                      <span>
                        {t('code_cree_par')}
                        {' : '}
                        {code.creatorUser.identifiant}
                      </span>
                    </Trans>
                  )}
                </p>
                <p className="lead">
                  {code.device && (
                    <Trans>
                      <span>
                        {t('code_produit')}
                        {' : '}
                        {code.device}
                      </span>
                    </Trans>
                  )}
                </p>
                <p className="lead">
                  {code.startDate && (
                    <span>
                      <Trans> {t('code_date_debut')} </Trans>
                      {' : '}
                      <Moment format="DD/MM/YYYY HH:mm:ss">
                        {moment.utc(code.startDate)}
                      </Moment>
                    </span>
                  )}
                </p>
                <p className="lead">
                  {code.endDate && (
                    <span>
                      <Trans>{t('code_date_fin')}</Trans>
                      {' : '}
                      <Moment format="DD/MM/YYYY HH:mm:ss">
                        {moment.utc(code.endDate)}
                      </Moment>
                    </span>
                  )}
                </p>
                <p className="lead">
                  {code.endDate && (
                    <span>
                      <Trans>{t('code_date_create')}</Trans>
                      {' : '}
                      <Moment format="DD/MM/YYYY HH:mm:ss">
                        {moment(code.generationDate).local()}
                      </Moment>
                    </span>
                  )}
                </p>
                <p className="lead">
                  {code.duration && (
                    <Trans>
                      <span>
                        {t('code_duration')}
                        {' : '}
                        {i18n.language === 'fr'
                          ? DURATION_FRENCH_EQUIV[code.duration]
                          : DURATION_ENG_EQUIV[code.duration]}
                      </span>
                    </Trans>
                  )}
                </p>
                <p className="lead">
                  {code.level && (
                    <span>
                      {t('code_level')}
                      {' : '}
                      {code.level}
                    </span>
                  )}
                </p>
                <p className="lead">
                  {code.description && (
                    <span>
                      {t('code_desc')}
                      {' : '}
                      {code.description}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

CodeDetail.propTypes = {
  getCodeById: PropTypes.func.isRequired,
  deleteCode: PropTypes.func.isRequired,
  sendCodeSMS: PropTypes.func.isRequired,
  sendCodeEmail: PropTypes.func.isRequired,
  code: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  code: state.code,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getCodeById,
  deleteCode,
  sendCodeSMS,
  sendCodeEmail
})(CodeDetail);
