import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getEventLog } from '../../actions/eventlog';
import EventItem from './EventItem';

const AffichageLog = ({
  auth,
  getEventLog,
  eventlog: { event, eventlog, loadinglog }
}) => {
  useEffect(() => {
    getEventLog();
  }, [getEventLog]);

  if (loadinglog || auth === null) {
    return (
      <section className="container">
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <Spinner />
          <p className="lead text-center">
            Recuperation du journal d'evenements...
          </p>{' '}
        </div>
      </section>
    );
  } else if (eventlog.length === 0) {
    return (
      <section className="container">
        <div style={{ marginTop: 100, marginBottom: 50 }}>
          <p className="lead text-center">Aucun evenement trouvé </p>{' '}
        </div>
      </section>
    );
  } else {
    return (
      <section className="container">
        <Fragment>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <h4 className="p-3 mb-2 bg-primary text-white text-center">
              Journal Evenements :
            </h4>
            {auth.user.rights === 'admin' ||
            auth.user.rights === 'admin_entity' ? (
              <Fragment>
                <table
                  className="table  table-striped table-light table-header-group table-container"
                  border="1"
                >
                  <thead>
                    <tr className="sticky">
                      <th className="text-center " id="th0">
                        index
                      </th>
                      <th className="text-center " id="th1">
                        date
                      </th>
                      {/* <th className="text-center " id="th2">
                    type
                  </th> */}
                      <th className="text-center" id="th3">
                        evenement
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventlog.map((event, index) => (
                      <EventItem event={event} key={event._id} index={index} />
                    ))}
                  </tbody>
                </table>
              </Fragment>
            ) : (
              <p>Accès non autorisé</p>
            )}
          </div>
        </Fragment>
      </section>
    );
  }
};

AffichageLog.propTypes = {
  auth: PropTypes.object.isRequired,
  getEventLog: PropTypes.func.isRequired,
  eventlog: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  eventlog: state.eventlog,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getEventLog
})(AffichageLog);
