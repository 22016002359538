import React, { Fragment } from 'react';
import { useTranslation, Trans } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <section className="container">
      <Fragment>
        <Trans>
          <h1 className="x-large text-primary">
            <i className="fas fa-exclamation-triangle" />
            {t('page_not_found')}
          </h1>
          <p className="large">{t('error_404')}</p>
          <p className="large">{t('try_back')}</p>
        </Trans>
      </Fragment>
    </section>
  );
};

export default NotFound;
