import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_CODE,
  GET_CODES,
  CODE_ERROR,
  UPDATE_CODE,
  CLEAR_CODE,
  CODE_DELETED
} from './types';

// Get all codes
// export const getCodes = () => async (dispatch) => {
//   dispatch({ type: CLEAR_CODE });

//   try {
//     const res = await axios.get('/api/code');

//     dispatch({
//       type: GET_CODES,
//       payload: res.data
//     });
//   } catch (err) {
//     dispatch({
//       type: CODE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// Get all codes for user
export const getCurrentUserCodes = () => async (dispatch) => {
  dispatch({ type: CLEAR_CODE });

  try {
    const res = await axios.get('/api/code/me');

    dispatch({
      type: GET_CODES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CODE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get all codes created by user
export const getCurrentUserCreatedCodes = () => async (dispatch) => {
  dispatch({ type: CLEAR_CODE });

  try {
    const res = await axios.get('/api/code/created');

    dispatch({
      type: GET_CODES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CODE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get code by ID
export const getCodeById = (codeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/code/id/${codeId}`);

    dispatch({
      type: GET_CODE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CODE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Send SMS to target user
export const sendCodeSMS = (codeId, lg) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post(
      `/api/code/sms/${codeId}`,
      {
        lg: lg
      },
      config
    );

    if (res.status === 200) dispatch(setAlert('SMS OK', 'success'));
  } catch (err) {
    dispatch({
      type: CODE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Send email to target user
export const sendCodeEmail = (codeId, lg) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const res = await axios.post(
      `/api/code/email/${codeId}`,
      {
        lg: lg
      },
      config
    );

    if (res.status === 200) dispatch(setAlert('Email OK', 'success'));
  } catch (err) {
    dispatch({
      type: CODE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Create or update code
export const createCode = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await axios.post('/api/code', formData, config);

    dispatch({
      type: UPDATE_CODE,
      payload: res.data
    });

    dispatch(setAlert('code crée avec succès', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CODE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Delete code
export const deleteCode = (codeId) => async (dispatch) => {
  if (
    window.confirm('Etes vous sur ? Cette opération ne peut PAS être annulée !')
  ) {
    try {
      await axios.delete(`/api/code/${codeId}`);

      dispatch({ type: CODE_DELETED });

      dispatch(
        setAlert('Le code a été effacé de manière definitive', 'danger')
      );
    } catch (err) {
      dispatch({
        type: CODE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }
};
