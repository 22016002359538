import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import eventlog from './eventlog';
import code from './code';
import user from './user';
import entity from './entity';

export default combineReducers({
  alert,
  auth,
  eventlog,
  user,
  entity,
  code
});
