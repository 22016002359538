import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaQuestionCircle } from 'react-icons/fa';
import Accordion from 'react-bootstrap/Accordion';
// import Spinner from '../layout/Spinner';
import { useTranslation } from 'react-i18next';
import badcred from '../../img/badcredential-faq.png';
import dashboard from '../../img/dashboard-faq.png';

const FaqCodegen = ({ auth }) => {
  const { t } = useTranslation();

  return (
    <section className="container">
      <Fragment>
        <Fragment>
          <h1 className="large text-ascorel">FAQ</h1>
          <div className="parent-flex">
            <p className="lead">
              <FaQuestionCircle
                size={40}
                color="grey"
                style={{
                  marginRight: '1rem'
                }}
              />{' '}
              {t('faq_lead')}
            </p>
          </div>

          <Accordion>
            <Accordion.Item eventKey="7">
              <Accordion.Header className="faq-title">
                {t('gen_presentation_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                {t('gen_presentation_msg')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header className="faq-title">
                {t('faq_connexion_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                {t('faq_connexion_msg_1')}
                <img
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  src={badcred}
                  alt="bad credentials"
                />
                {t('faq_connexion_msg_2')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header className="faq-title">
                {t('dashboard_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                <img
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  src={dashboard}
                  alt="dashboard description"
                />
                {t('dashboard_msg')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="faq-title">
                {t('consult_code_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                {t('consult_code_msg')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="faq-title">
                {t('faq_quick_code_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                <p>{t('quick_code_msg')}</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="faq-title">
                {t('manage_code_create_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                {t('manage_code_create_msg')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="faq-title">
                {t('manage_code_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                {t('manage_code_msg')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="faq-title">
                {t('manage_user_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                {t('manage_user_msg')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header className="faq-title">
                {t('what_entity_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                {t('what_entity_msg')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header className="faq-title">
                {t('code_not_working_title')}
              </Accordion.Header>
              <Accordion.Body className="faq-msg">
                {t('code_not_working_msg')}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Fragment>
      </Fragment>
    </section>
  );
};

FaqCodegen.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(FaqCodegen);
