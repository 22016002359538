import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';
import { MdInfoOutline } from 'react-icons/md';
import { useTranslation, Trans } from 'react-i18next';

const DURATION_FRENCH_EQUIV = {
  day: 'Journée',
  week: 'Semaine',
  month: 'Mois',
  trimester: 'Trimestre',
  semester: 'Semestre',
  year: 'Année'
};

const DURATION_ENG_EQUIV = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  trimester: 'Trimester',
  semester: 'Semester',
  year: 'Year'
};

const CodeItem = ({
  code: {
    _id,
    creatorUser,
    targetUser,
    device,
    startDate,
    endDate,
    duration,
    generationDate
  },
  valid,
  userOrCreator
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      {(valid && moment().isBetween(startDate, endDate)) ||
      (!valid && !moment().isBetween(startDate, endDate)) ? (
        <Fragment>
          <tr>
            <td className="text-center align-middle ">
              <Link to={`/code/${_id}`}>
                <button className="btn btn-ascorel ">
                  Details <MdInfoOutline color="white" />
                </button>
              </Link>
            </td>
            <td className="text-center align-middle hide-mobile">
              {userOrCreator !== 'user' ? (
                <div>
                  {creatorUser && creatorUser.identifiant ? (
                    <span className="text-center align-middle">
                      {creatorUser.identifiant}
                    </span>
                  ) : (
                    <span className="text-center align-middle">
                      utilisateur inconnu
                    </span>
                  )}
                </div>
              ) : (
                <div>
                  {targetUser && targetUser.identifiant ? (
                    <span className="text-center align-middle">
                      {targetUser.identifiant}
                    </span>
                  ) : (
                    <span className="text-center align-middle">
                      utilisateur inconnu
                    </span>
                  )}
                </div>
              )}
            </td>
            <td className="text-center align-middle hide-mobile">{device}</td>
            <td className="text-center align-middle" width="100">
              <Moment format="DD/MM/YYYY">{moment.utc(startDate)}</Moment>
            </td>
            <td className="text-center align-middle hide-mobile" width="100">
              <Moment format="DD/MM/YYYY">{moment.utc(endDate)}</Moment>
            </td>
            <td className="text-center align-middle hide-mobile" width="100">
              <Moment format="DD/MM/YYYY HH:mm:ss">
                {moment(generationDate).local()}
              </Moment>
            </td>
            <td className="text-center align-middle">
              {i18n.language === 'fr'
                ? DURATION_FRENCH_EQUIV[duration]
                : DURATION_ENG_EQUIV[duration]}
            </td>
            <td className="text-center hide-mobile" width="10px">
              {moment().isBefore(startDate) ? (
                <div className="text-center align-middle badge badge-pill bg-secondary p-2 mb-2 ">
                  <Trans>{t('waiting')}</Trans>
                </div>
              ) : (
                <Fragment>
                  {moment().isAfter(endDate) ? (
                    <div className="text-center align-middle badge badge-pill bg-warning p-2 mb-2 ">
                      <Trans>{t('expired')}</Trans>
                    </div>
                  ) : (
                    <Fragment>
                      {moment().isBetween(startDate, endDate) ? (
                        <div className="text-center align-middle badge badge-pill bg-primary p-2 mb-2 ">
                          <Trans>{t('valid')}</Trans>
                        </div>
                      ) : (
                        <div className="text-center align-middle badge badge-pill bg-danger p-2 mb-2 ">
                          <Trans>{t('unknown')}</Trans>
                        </div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </td>
          </tr>
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </Fragment>
  );
};

CodeItem.propTypes = {
  code: PropTypes.object.isRequired
};

export default CodeItem;
