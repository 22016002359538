import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getEntityById, deleteEntity } from '../../actions/entity';
import { MdDelete } from 'react-icons/md';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { AiOutlineEdit } from 'react-icons/ai';
import { useTranslation, Trans } from 'react-i18next';

const EntityDetail = ({
  entity_id,
  getEntityById,
  deleteEntity,
  entity: { entity, loading },
  auth
}) => {
  const DeleteEntite = () => {
    setaction(true);
    deleteEntity(entity_id ? entity_id : id);
    setTimeout(() => {
      navigate('/entities');
    }, 1000);
  };
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [action, setaction] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getEntityById(entity_id ? entity_id : id);
  }, [getEntityById, id, entity_id]);

  return (
    <section className="container">
      <Fragment>
        {entity === null || loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="row">
              {auth.user.rights === 'user' ? (
                <div className="col-auto">
                  <Trans>
                    <Link
                      to="/dashboard"
                      className="btn btn-dark"
                      style={{
                        marginBottom: '1rem'
                      }}
                    >
                      <AiOutlineArrowLeft /> {t('back_dasboard')}
                    </Link>
                  </Trans>
                </div>
              ) : (
                <div className="col-auto">
                  <Trans>
                    {' '}
                    <Link
                      to="/entities"
                      className="btn btn-dark"
                      style={{
                        marginBottom: '1rem'
                      }}
                    >
                      <AiOutlineArrowLeft /> {t('back_list_ent')}
                    </Link>
                  </Trans>
                </div>
              )}
              {auth.isAuthenticated &&
                auth.loading === false &&
                auth.user.rights === 'admin' && (
                  <div className="col-auto">
                    <Trans>
                      <Link
                        to={`/edit-entity/${entity_id ? entity_id : id}`}
                        className="btn btn-dark"
                        style={{
                          marginBottom: '1rem'
                        }}
                      >
                        {t('modify_entity')} <AiOutlineEdit />
                      </Link>
                    </Trans>
                  </div>
                )}

              {auth.isAuthenticated &&
                auth.loading === false &&
                auth.user.rights === 'admin' && (
                  <div className="col-auto">
                    <Trans>
                      <button
                        className="btn btn-danger btn-lg"
                        style={{
                          marginBottom: '1rem'
                        }}
                        onClick={() => DeleteEntite()}
                      >
                        {t('del_entity')} <MdDelete />
                      </button>
                    </Trans>
                  </div>
                )}
            </div>
            {auth.isAuthenticated &&
              auth.loading === false &&
              auth.user.rights === 'admin' && (
                <div>
                  <div className="profile-grid my-1">
                    <div className="profile-top bg-primary p-2">
                      <Trans>
                        <p className="lead">
                          {entity.name && (
                            <span>
                              {' '}
                              {t('name')} : {entity.name}
                            </span>
                          )}
                        </p>

                        <p className="lead">
                          {entity.maxLvlAdminEntity && (
                            <span>
                              {t('lvl_max_admin')} :{' '}
                              {entity.maxLvlAdminEntity.toString()}
                            </span>
                          )}
                        </p>
                        <p className="lead">
                          {entity.maxLvlUser && (
                            <span>
                              {t('lvl_max_user')} :{' '}
                              {entity.maxLvlUser.toString()}
                            </span>
                          )}
                        </p>
                      </Trans>
                    </div>
                  </div>
                </div>
              )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

EntityDetail.propTypes = {
  getEntityById: PropTypes.func.isRequired,
  deleteEntity: PropTypes.func.isRequired,
  entity: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  entity: state.entity,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getEntityById,
  deleteEntity
})(EntityDetail);
