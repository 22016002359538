import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';

const UserItem = ({
  user: {
    _id,
    name,
    email,
    phone,
    identifiant,
    rights,
    entity,
    date,
    enabled,
    lastSeen
  }
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <tr>
        <td className="text-center ">
          {enabled ? (
            <div className="text-center align-middle badge badge-pill badge-success p-2 mb-2 ">
              <Trans>{t('activated')}</Trans>
            </div>
          ) : (
            <div className="badge badge-pill badge-danger  p-2 mb-2 ">
              <Trans>{t('deactivated')}</Trans>
            </div>
          )}
        </td>
        <td
          className="text-center align-middle "
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          <Link to={`/user/${_id}`}>
            <span className="text-center align-middle">{identifiant}</span>
          </Link>
        </td>
        {/* <td
          className="text-center align-middle hide-mobile"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          {email}
        </td> */}
        {/* <td
          className="text-center align-middle hide-mobile"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          {phone}
        </td> */}

        <td
          className="text-center align-middle hide-mobile"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          {name}
        </td>
        <td
          className="text-center align-middle hide-mobile"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          {rights}
        </td>
        <td
          className="text-center align-middle hide-mobile"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          {entity}
        </td>
        <td
          className="text-center align-middle hide-mobile"
          width="100"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          {lastSeen ? (
            <Moment format=" DD/MM/YYYY hh:mm:ss">
              {moment(lastSeen).local()}
            </Moment>
          ) : (
            <div> --- </div>
          )}
        </td>
        <td
          className="text-center align-middle hide-mobile"
          width="100"
          style={{
            textDecorationLine: !enabled ? 'line-through' : ''
          }}
        >
          <Moment format=" DD/MM/YYYY hh:mm:ss">{moment(date).local()}</Moment>
        </td>
      </tr>
    </Fragment>
  );
};

UserItem.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserItem;
