import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams, useMatch, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createEntity, getEntityById } from '../../actions/entity';
import Spinner from '../layout/Spinner';
import { useTranslation, Trans } from 'react-i18next';

const initialState = {
  name: '',
  maxLvlAdminEntity: '',
  maxLvlUser: ''
};

const arr = [...Array(17)].map((x) => 0);

const EntityEdit = ({
  auth,
  entity: { entity, loading },
  createEntity,
  getEntityById,
  history
}) => {
  const [formData, setFormData] = useState(initialState);
  const [action, setaction] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const creatingEntity = useMatch('/create-entity');

  useEffect(() => {
    if (!entity && !creatingEntity) {
      getEntityById(id);
    }
    if (!loading) {
      const entityData = { ...initialState };
      for (const key in entity) {
        if (key in entityData) entityData[key] = entity[key];
      }
      setFormData(entityData);
    }
  }, [loading, entity, id, getEntityById, creatingEntity]);

  const { name, maxLvlAdminEntity, maxLvlUser } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    createEntity(entity ? entity._id : null, formData, history);
    setaction(true);
    setTimeout(() => {
      navigate('/entities');
    }, 1000);
  };

  return (
    <section className="container">
      <Fragment>
        {action ? (
          <Spinner />
        ) : (
          <Fragment>
            {auth.user.rights === 'admin' ? (
              <Fragment>
                <h1 className="large text-ascorel">
                  {creatingEntity ? (
                    <Trans>{t('creat_entity_lvl')}</Trans>
                  ) : (
                    <Trans>{t('modify_entity')}</Trans>
                  )}
                </h1>
                <p className="lead">
                  <i className="fas fa-user" />
                  {creatingEntity ? (
                    <Trans>{t('creer_code_lead')}</Trans>
                  ) : (
                    <Trans>{t('modify_entity_lvl')}</Trans>
                  )}
                </p>
                <Trans>
                  <small>{t('champs_requis')}</small>
                </Trans>
                <form className="form" onSubmit={onSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="*Nom"
                      name="name"
                      value={name}
                      onChange={(e) => onChange(e)}
                    />
                    <Trans>
                      <small>{t('must_unique')}</small>
                    </Trans>
                  </div>
                  <div className="form-group">
                    <select
                      name="maxLvlAdminEntity"
                      value={maxLvlAdminEntity}
                      onChange={(e) => onChange(e)}
                    >
                      <Trans>
                        <option value="">{t('select_admin_lvl')}</option>
                      </Trans>
                      {arr.map((value, index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                    <Trans>
                      <small className="form-text">{t('admin_lvl_hint')}</small>
                    </Trans>
                  </div>
                  <div className="form-group">
                    <select
                      name="maxLvlUser"
                      value={maxLvlUser}
                      onChange={(e) => onChange(e)}
                    >
                      <Trans>
                        <option value="">{t('select_user_lvl')}</option>
                      </Trans>
                      {arr.map((value, index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                    <Trans>
                      <small className="form-text">{t('user_lvl_hint')}</small>
                    </Trans>
                  </div>
                  <Trans>
                    <input
                      type="submit"
                      className="btn btn-primary my-1"
                      value={t('save')}
                    />
                  </Trans>
                  <Trans>
                    <Link className="btn btn-light my-1" to="/entities">
                      {t('cancel')}
                    </Link>
                  </Trans>
                </form>
              </Fragment>
            ) : (
              <Trans>
                <p>{t('unauthorized_access')}</p>
              </Trans>
            )}
          </Fragment>
        )}
      </Fragment>
    </section>
  );
};

EntityEdit.propTypes = {
  createEntity: PropTypes.func.isRequired,
  getEntityById: PropTypes.func.isRequired,
  entity: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  entity: state.entity,
  auth: state.auth
});

export default connect(mapStateToProps, {
  createEntity,
  getEntityById
})(EntityEdit);
