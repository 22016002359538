import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';

const EventItem = ({ index, event: { _id, comment, date } }) => {
  return (
    <tr>
      <td className="text-center align-middle " id="th0" width="50">
        {index}
      </td>
      <td className="text-center align-middle text-nowrap" id="th1">
        <Moment format="DD/MM/YYYY HH:mm:SSS">{moment(date).local()}</Moment>
      </td>

      <td className="text-left align-middle" id="th3">
        {comment}
      </td>
    </tr>
  );
};

EventItem.propTypes = {
  event: PropTypes.object.isRequired
};

export default EventItem;
