import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import NavbarACG from './components/layout/NavbarACG';
import Landing from './components/layout/Landing';
import Login from './components/auth/Login';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
import Userlist from './components/users/UserList';
import UserDetail from './components/users/UserDetail';
import UserEdit from './components/users/UserEdit';
import NotFound from './components/layout/NotFound';
import PrivateRoute from './components/routing/PrivateRoute';
import AffichageLog from './components/log/AffichageLog';
import EntityList from './components/entity/EntityList';
import EntityDetail from './components/entity/EntityDetail';
import EntityEdit from './components/entity/EntityEdit';
import CodeDetail from './components/code/CodeDetail';
import CodeList from './components/code/CodeList';
import CodeCreate from './components/code/CodeCreate';
import CodeCreatedList from './components/code/CodeCreatedList';
import FaqCodegen from './components/faq/FaqCodegen';
// import ActivateUser from './components/activation/ActivateUser';
import VerifyEmailResetPassword from './components/activation/VerifyEmailResetPassword';
import ResetPassword from './components/activation/ResetPassword';
import CGUacg from './components/activation/CGUacg';
import { LOGOUT } from './actions/types';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'; // obligatoire pour faire fonctionner les popper, modal et dropdown
import './App.css';

const App = () => {
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());
    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <NavbarACG />
        <Alert />
        {/* <section className="container"> */}
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/activation/:id/:token" element={<ResetPassword />} />
          <Route path="/verifyemail" element={<VerifyEmailResetPassword />} />
          <Route path="/resetpassword/:id/:token" element={<ResetPassword />} />
          <Route path="/cgu" element={<CGUacg />} />
          <Route path="/faq" element={<FaqCodegen />} />
          <Route
            path="/users"
            element={<PrivateRoute component={Userlist} />}
          />
          <Route
            path="/user/:id"
            element={<PrivateRoute component={UserDetail} />}
          />
          <Route
            path="/entities"
            element={<PrivateRoute component={EntityList} />}
          />
          <Route
            path="/entity/:id"
            element={<PrivateRoute component={EntityDetail} />}
          />
          <Route
            path="/edit-entity/:id"
            element={<PrivateRoute component={EntityEdit} />}
          />
          <Route
            path="/create-entity"
            element={<PrivateRoute component={EntityEdit} />}
          />
          <Route
            path="/dashboard"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route
            path="/edit-user/:id"
            element={<PrivateRoute component={UserEdit} />}
          />
          <Route
            path="/create-user"
            element={<PrivateRoute component={UserEdit} />}
          />
          <Route
            path="/code/:id"
            element={<PrivateRoute component={CodeDetail} />}
          />
          <Route
            path="/codes"
            element={<PrivateRoute component={CodeList} />}
          />
          <Route
            path="/codescreated"
            element={<PrivateRoute component={CodeCreatedList} />}
          />
          <Route
            path="/create-code"
            element={<PrivateRoute component={CodeCreate} />}
          />
          <Route
            path="/eventlog"
            element={<PrivateRoute component={AffichageLog} />}
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        {/* </section> */}
      </Router>
    </Provider>
  );
};

export default App;
